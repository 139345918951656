import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
// import CarouselPeople from "../CarouselPeople";
import Video from '../Video'
import PTestimonial from "../cards/PTestimonial"
import Grid from "@material-ui/core/Grid"

const styles = (theme, prop) => ({
    root: {
        marginTop: '3rem',
        padding: '5%',
        // width: "100vw",
        display: "flex",
        "flex-direction": "column",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center"
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: '2rem',
        justifyContent: 'center'
    }

})
class Customer extends Component {
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                {/* customer */}
                <h2 style={{ textTransform: "capitalize", }} dangerouslySetInnerHTML={{ __html: data.ppl.header }}></h2>
                {/* <p style={{ margin: "2rem 0", maxWidth: 510, }} dangerouslySetInnerHTML={{ __html: data.ppl.subheader }}></p> */}
                {/* <CarouselPeople {...data.ppl} /> */}
                <Grid item xs={12} className={classes.planContainer}>
                    {data.ppl.lists.map((value, index) => {
                        return (
                            <PTestimonial {...value} key={index} />
                        )
                    })}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Customer)