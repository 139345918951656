import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button'
import classNames from 'classnames'


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        minWidth: 275,
        margin: '5%',
        padding: '2%',
        position: 'relative',
        height: 250,

        // [theme.breakpoints.down('xs')]: {
        //     height: 250
        // }
    },
    title: {
        fontSize: 14,
    },
    pos: {
        position: 'absolute',
        right: '1%',
        bottom: '1%',

    },
    border: {
        boxShadow: "2px 2px 6px 6px rgba(17, 211, 188, 0.25)",
        borderColor: 'rgba(17, 211, 188, 0.25) !important'
    }
}));

const Plan = props => {

    const classes = useStyles();
    const borderstyle = classNames(classes.root, classes.border)

    return (
        <Card className={props.index === 0 ?
            borderstyle : classes.root}>
            <CardContent>
                <h3>
                    {props.title}
                </h3>
                <p>
                    {props.body}
                </p>
            </CardContent>
            <CardActions className={classes.pos}>
                <Button size="small" color="secondary" onClick={props.link}>Learn More </Button>
            </CardActions>
        </Card>
    )
}

export default Plan