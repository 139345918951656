import React from "react";
import data from "../../../data/landing.js"
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Video from '../../parts/Video'

const styles = makeStyles((theme, prop) => ({
    root: {
        marginTop: '3rem',
        display: "flex",
        "flex-direction": "column",
        //justifyContent: "center",
        alignItems: "center"
    },
    text: {
        width: "75vw",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            width: "40vw",
        }
    },
    title: {
        width: "80vw",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            width: "30vw",
        }
    }
}))
const FutureOfVR = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const classes = styles(isSmallScreen)
    return (
        <div className={classes.root}>
            {/*Future of VR */}
            <h2 className={classes.title}>{data.future.header}</h2>
            <p className={classes.text}>{data.future.para}</p>
            <Video {...data.video2} />
        </div>
    )

}

export default FutureOfVR