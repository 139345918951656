import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import LeftImgEmpower from '../cards/LeftImgEmpower'
import RightImgEmpower from '../cards/RightImgEmpower'


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '2rem',
        padding: '5%',
        textAlign: 'center'
    },
    txt: {
        display: 'inline-block',
        maxWidth: 500,
        margin: '2rem'
    }
}));

const EmpowerTeacher = props => {
    const classes = useStyles();

    return (

        <Grid item xs={12} className={classes.root}>
            <h2 align="center">{props.header}</h2>
            <p className={classes.txt}>{props.body}</p>
            {props.lists.map((value, index) => {
                if ((index % 2) === 0) {
                    return (
                        <LeftImgEmpower {...value} key={index} />
                    )
                } else {
                    return (
                        <RightImgEmpower {...value} key={index} />
                    )
                }

            })}
        </Grid>

    )
}

export default EmpowerTeacher