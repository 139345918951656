import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Plan from "../cards/Plan";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'block',
        marginTop: '2rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '5%'
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: '2rem',
        justifyContent: 'center'
    }

}));

const ChoosePlan = props => {
    const classes = useStyles();
    const history = useHistory();

    const routeToJava = () => {
        history.push('/java')
    }

    const routeToSchool = () => {
        //console.log('Go to School')
        props.linkSchoolInfo()
    }

    return (

        <Grid item xs={12} className={classes.root}>
            <h2 className={classes.header} align="center">{props.title}</h2>
            <Grid item xs={12} className={classes.planContainer}>
                {props.plans.map((value, index) => {
                    return (

                        <Plan {...value} index={index} key={index} link={() => { index === 1 ? routeToJava() : routeToSchool() }} />

                    )
                })}
            </Grid>


        </Grid>

    )
}

export default ChoosePlan