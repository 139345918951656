
import jacob from "../assets/testimonial/jacob.png";
// import student from "../assets/avatar/undraw_profile_pic_ic5t.png"
// import parent from "../assets/avatar/undraw_female_avatar_w3jk.png"

import ciirve from "../assets/Companies/Ciirve.svg";
import capilano from "../assets/Companies/Capilano.svg";
import lang from "../assets/Companies/Langara.svg";
import zenmaker from "../assets/Companies/ZenMakerLab.svg";
import frame from "../assets/Frame.png";

import innovative from "../assets/innovative.svg";

import benefits1 from "../assets/Benefits/benefits1.svg";
import benefits2 from "../assets/Benefits/benefits2.svg";
import benefits3 from "../assets/Benefits/benefits3.svg";

import codingclass from '../assets/coding-class-for-kids.jpg'
import codingclassWebp from '../assets/coding-class-for-kids.webp'

// import headerImage from "../assets/vrgameshot.png"
import headerImage from "../assets/virtual-reality-coding.png"
import headerImageWebp from "../assets/virtual-reality-coding.webp"


const data = {
  topEducation: {
    imgWebp: headerImageWebp, //TO REPLACE
    img: headerImage,
    header: "Virtual Reality coding for Education",
    para: ["Teach and learn to code online through engaging Virtual Reality educational lessons to prepare children for the future"],
    button1: "Educators",
    button2: "Parents",
  },
  webxr: {
    header: "NEWS! New C3D Product for Oculus Browser - C3D WebXR is here",
    para: "C3D WebXR is a new product of C3D that is being developed for the Oculus browser on Oculus Quest 2* instead of our existing solution for smartphone and cardboard headsets. C3D WebXR beta is available free until July 15, 2022. Beta users will qualify for 15% discount for C3D School License purchase. To learn more about C3D WebXR and/or join the beta test, contact us using the Contact Form at the bottom of the School page. (Oculus is trademark of Facebook Technologies, LLC)",
  },
  video1: {
    // header: "See how it works",
    link: "https://www.youtube.com/embed/HX_t4S9dqkY" 
  },
  future: {
    header: "C3D - the future of virtual reality education",
    para: "C3D is an innovative teaching tool that is easy to use for teachers, fun, and engaging for students by bringing visual code blocks to life. Students create their own virtual reality games or animations while learning coding concepts and explore their creations with just a mobile phone and a VR cardboard headset",
    img: ciirve //TO REPLACE

  },
  video2: {
    // header: "See how it works",
    link: "https://www.youtube.com/embed/b_Ro16hGyIU"
  },
  linkTo: {
    header: "Start Virtual Reality Education",
    para: "Children can learn coding concepts while creating their own VR world and with one click, explore their creations with a mobile phone and a VR cardboard.<br/>C3D provides an innovative teaching tool that children can learn to code. Bring virtual reality education to your home and classes.",
    img: ciirve //TO REPLACE

  },
  notice: {
    header: "Our response to the Covid-19 outbreak",
    para: "As the impact of the restrictions on our day-to-day life are felt by all of us due to the novel coronavirus outbreak, C3D team is determined to support and serve schools, teachers, parents and children in the midst of the crisis. We are here to help you continue children's coding education. ",
    img: ciirve //TO REPLACE

  },
  innovative: {
    subheader: "An Innovative web-based VR Coding Platform",
    lists: ["Increase student interest in coding",
      "Fully protecte student privacy and data",
      "Makes coding concepts stick",
      "Higher learning outcomes compared to 2D coding platforms"],
    img: innovative
  },
  inspire: {
    header: "Inspiring Children, Empowering Educators",
    para: "We want to help teachers without computer science knowledge deliver coding curriculum easily while inspiring children to explore the exciting world of interactive computer programming and virtual reality education and empowering educators teaching kids to code for the first time.",
    img: frame, //TODO (animated),
    subheader: "Bring VR Education to Your Classroom",
    lists: [
      "Teach code like an art lesson and be a facilitator for students’<b>self-learning and discovery</b>",
      "Access to an <b>engaging curriculum</b> for a stress-free VR education teaching experience",
      "Teach with an <b>intuitive class management portal</b> featuring a teacher dashboard, interactive self-learning modules for students, lesson plans and more",
      "<b>Premium privacy protection</b> for students with a personalized site for your school and school-based authentication"
    ]
  },
  futureEducation: {
    header: "VR: the future of coding",
    para: "Imagine interacting with a small section of a browser using a keyboard versus a 360 degree virtual relaity world you created from your own imagination - this is the difference between a 2D coding platform and VR coding. This is the future of coding.",
    subheader: "Benefits of VR in Education",
    img: benefits1,
    lists: [
      {
        img: benefits1,
        caption: "<b>VR is engaging</b>. Studies have indicated that VR significantly increases student engagement and achieves higher learning outcomes.",
      },
      {
        img: benefits2,
        caption: "VR helps students <b>learn by experience</b> rather than simply being presented with information by the teacher.",
      },
      {
        img: benefits3,
        caption: "VR gives students the opportunity to <b>express their artistic creativity and imagination</b> in a real-life 360-degree world."

      }
    ]
  },
  ages: {
    imgUrl: codingclass,
    imgUrlWebp: codingclassWebp,
    header: "A clear path to learning code with C3D",
    lists: [
      {
        title: "Age 7-10",
        text: "online lessons",
        link: "Learn More",
        src: "https://www.c3d.io/product-category/online-coding-class/", //TODO
      },
      {
        title: "Age 10-12",
        text: "online lessons or self-learning modules",
        link: "Learn More",
        //src: "/parents", //TODO
      },
      {
        title: "Age 13-15",
        text: "self-learning modules",
        link: "Learn More",
        //src: //"/parents", //TODO
      },
      {
        title: "Age 16 and up",
        text: "Java version",
        link: "Learn More",
        src: "/java", //TODO
      }
    ]


  },
  ppl: {
    header: "Don’t take our word for it!",
    subheader: "Trusted by teachers, students, parents...",
    lists: [
      {
        title: "C3D is a wonderful way to blend programming concepts with artistic creativity. It provides a framework for teachers to create engaging curriculum that is relevant and cutting-edge. The lesson plans were clear, concise and effective.",
        img: jacob, //TODO
        name: "Jacob Tran,Teacher"
      },
      {
        title: "I took 4 continuous classes this week to build a Zombie game.This was my first time trying out coding and Tomoko taught me many things and helped me in different ways. Her teaching was very clear and easy to understand. It also made me think about what programmers do for their jobs and made me engage with coding.It was a very interesting experience for me. Thank you very much.",
        img: '',//student, //TODO 
        name: "Kai, Student"
      },
      {
        title: "C3D is great! My son, Kai, was very engaged in the VR game class. He was so proud to show me the VR game he programmed. This is the skill he can use in the future! I have signed him up for another class! ",
        img: '',//parent, //TODO 
        name: "Amy Nishimura, Parent "
      }
    ]
  },
  company: {
    subheader: "Trusted by leading brands ...",
    imgs: [ciirve, capilano, lang, zenmaker, ciirve]
  }
};

export default data;
