import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Subscribe from '../Subscribe'
import { useMediaQuery } from "@material-ui/core"


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px !important'
    },
    nested: {
        paddingLeft: theme.spacing(4),
        display: 'block',
        textAlign: 'center'
    },
    bggray: {
        marginTop: '20px !important',
        [theme.breakpoints.up("960")]: {
            backgroundImage: "linear-gradient(right, #EDEEEA , #EDEEEA 40%, transparent 30%, transparent 100%)"

        }
    },
    box: {
        [theme.breakpoints.up("960")]: {
            textAlign: 'center',
            padding: "1vw 2vw 2vw 2vw !important",
        },
        width: "100%",
        margin: '0',
    },
    button: {
        padding: "0.5vw 3vw !important",
        marginRight: "3vw !important",
        marginBottom: "8%!important",
        backgroundColor: "#FC5185 !important",
        borderRadius: "0.5vw !important",
        border: "none",
        color: "#FFFFFF",
        fontSize: "1.25vw !important",
        // float: "center"
    },
    input: {
        width: "23vw !important",
        paddingTop: "0.7vw !important",
        paddingBottom: "0.7vw !important",
    },
}));

const NestedList = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("600"))

    const handleClick = () => {
        setOpen(!open);
    };
    function changeSubmit(e) {
        e.target.style.padding = "1vw 3.3vw";
        e.target.style.fontSize = "1.4vw";
    }
    function revertedSubmit(e) {
        e.target.style.padding = "0.5vw 3vw";
        e.target.style.fontSize = "1.25vw";
    }

    return (
        (props.title === "Newsletter" ?
            <Card className={open ? classes.bggray : classes.root}>

                <ListItem button onClick={handleClick}>
                    <ListItemText>
                    <span style={{paddingLeft:'20px'}}>{props.title}</span></ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <Grid item container xs={12}>

                                <Grid item xs={12} md={7}>

                                    <ListItemText primary={props.description} />

                                </Grid>

                                <Grid item xs={12} md={5} className={classes.gridbox}>
                                    <div className={classes.box}>

                                        <Subscribe formId={1761739} />
                                    </div>
                                </Grid>

                            </Grid>

                        </ListItem>
                    </List>
                </Collapse>
            </Card >
            :
            <Card className={classes.root}>
                <ListItem button onClick={handleClick}>
                <ListItemText>
                    <span style={{paddingLeft:'20px'}}>{props.title}</span></ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <div style={{padding:'20px', display:'flex'}}>

                        {/* <ListItem button className={classes.nested}> */}
                            {/* <ListItemText primary={props.description} /> */}
                            <div style={{ width: '70% !important', marginRight:'10px' }}  align="left" dangerouslySetInnerHTML={{ __html: props.description }}></div>
                            {
                                props.index === 0 ?
                                    <img style={{ width: '40%',height:'auto' }} alt="students coding together" src={props.imgUrl} /> :
                                    ''
                            }

                        {/* </ListItem> */}
                        </div>
                    </List>
                </Collapse>
            </Card>
        )

    );
}

export default NestedList