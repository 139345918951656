import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"


import AppBarCollapse from "../parts/header/AppBarCollapse"

const styles = theme => ({
  root: {
    padding: "2px 30px",
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 2000
  },
})
class Header extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.root}>
        <AppBarCollapse />
      </div>
    );
  }
}

export default withStyles(styles)(Header);
