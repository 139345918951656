import React from "react";
import data from "../../data/landing.js";
import { withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import benefits1 from "../../assets/Benefits/benefits1.svg";


const styles = makeStyles((theme, prop) => ({
    root: {
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",
        paddingBottom: "20vw",
        [theme.breakpoints.up('sm')]: {
            // height: "60vh",
            paddingBottom: "2vw",
        },
        [theme.breakpoints.up('md')]: {
            // height: "90vh",
        },
    },
    text: {
        width: "70vw",
        [theme.breakpoints.up('md')]: {
            width: "40vw",

        },
        textAlign: "center",
    },
    title: {
        width: "75vw",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            width: "25vw",
        },
    },
    bullet: {
        color: "#11D3BC",
        [theme.breakpoints.down('md')]: {
            textAlign: "left",
        },

    },
    list: {
        color: "#000000",
    },
    company: {
        margin: "3.5vw",
        display: "inline-flex",
        "flex-direction": "column",

    },
    smallContainer: {
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%"
        }, maxWidth: "75vw", textAlign: "center"
    }
}))
const VRFutureEducation = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = styles()

    return (
        <div className={classes.root}>
            {/* VR future education */}
            <h2 className={classes.title}>{data.futureEducation.header}</h2>
            <p className={classes.text}>{data.futureEducation.para}</p>
            <h3 style={{ color: "#11D3BC" }}>{data.futureEducation.subheader}</h3>
            {isSmallScreen ?
                <div className={classes.smallContainer}>
                    <img src={benefits1} alt="" />
                    <ul className={classes.bullet}>
                        {data.futureEducation.lists.map((item, index) => (
                            <li key={index} style={{ paddingBottom: "2vw" }}><span className={classes.list}> <div dangerouslySetInnerHTML={{ __html: item.caption }}></div></span></li>
                        ))}
                    </ul>

                </div>
                :
                <div style={{ maxWidth: "100vw", textAlign: "center" }}>

                    {data.futureEducation.lists.map((item, index) => (
                        <div key={index} className={classes.company}
                        // style={{ position: "relative", top: index % 2 === 0 ? "25vh" : "-3vh" }}
                        >
                            <img src={item.img} alt="" />
                            <figcaption style={{ maxWidth: "20vw", position: "relative", marginTop: "20px" }}>  <div dangerouslySetInnerHTML={{ __html: item.caption }}></div></figcaption>
                        </div>
                    ))}
                </div>
            }


        </div>


    )

}

export default VRFutureEducation