import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import GeneralBtn from "../elems/GeneralBtn"
import Box from '@material-ui/core/Box'
import { useMediaQuery } from "@material-ui/core"
import { useHistory } from "react-router-dom"




const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        margin: '4rem',
        padding: '0 1% 1% 1%',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            margin: '2rem 1rem 2rem 1rem',
        }
    },
    title: {
        // marginBottom: '2%'
    },
    content: {
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
        padding: '3% 8% 2% 8%',
        // [theme.breakpoints.down('md')]: {
        //     padding: '2%',
        // }
    },
    btn: {
        marginTop: '2%',
        paddingBottom: '5%'
    },
    stylebtn: {
        color: '#11D3BC',
        backgroundColor: 'white',
        border: '1px solid #11D3BC'
    }

}));

const SchoolPlan = props => {
    const classes = useStyles()
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("600"))
    const history = useHistory();

    const routeToSchool = () => {
        history.push('/school')
    }

    return (
        <Grid className={classes.root} >

            <Grid container sx={8}>
                <Card className={classes.content} variant="outlined">
                    <CardContent>
                        <h2 className={classes.title} style={{ color: '#11D3BC' }}>
                            {props.title}
                        </h2>
                        <p>
                            {props.description}
                        </p>
                    </CardContent>
                    <div style={{
                        textAlign: "left",
                        padding: '3% 8% 2% 8%',
                    }}>
                        {props.details}
                    </div>
                    <div className={classes.btn}>
                        <GeneralBtn label="Get a Quote" className={classes.btn} link={routeToSchool} />
                        {/* {isSmallScreen ?
                            '' :
                            <Box m={2} style={{ display: 'inline' }} />
                        } */}

                        {/* <GeneralBtn stylebtn={classes.stylebtn} label="Contact Us for Quote" link={routeToSchool} /> */}


                    </div>
                </Card>

            </Grid>
        </Grid>
    )
}

export default SchoolPlan