import React, { useRef, useEffect } from 'react'

import HeroLeftText from '../parts/HeroLeftText'
import data from "../../data/java"
import Getstart from '../parts/Getstart'
import Video from '../parts/Video'
import Features from '../parts/Features'
import JavaContent from '../parts/java/JavaContent'
import JavaLessonPlan from '../parts/java/JavaLessonPlan'
import Python from '../parts/Python'
import ContactForm from '../parts/ContactForm'
import JavaTestimonial from '../parts/java/JavaTestimonial'
import Quote from '../parts/Quote'

const scrollToRefObject = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })

const Java = () => {
    const getStart = useRef(Getstart)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleSignUpClick = () => {
        window.open('https://java.c3d.io');
    }
    const getStartScroll = () => scrollToRefObject(getStart)
    return (
        <div style={{
            textAlign: 'center', display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }} >
            <HeroLeftText {...data.hero} signupLink={() => getStartScroll()} />
            <Features {...data.features} />
            <Video {...data.video} />
            <div ref={getStart} style={{ width: '80%' }}>
                <Getstart {...data.getStart} signupLink={() => handleSignUpClick()} />
            </div>
            <JavaLessonPlan {...data.plans} />
            <JavaContent {...data.contents} />
            <JavaTestimonial {...data.testimonial} />
            <Python />

            <Getstart {...data.getStart} signupLink={() => handleSignUpClick()} />

            <Quote {...data.quote} />
            <ContactForm />
        </div >
    )
}

export default Java