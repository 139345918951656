import React, { useState } from 'react'
import CookieConsent from "react-cookie-consent";


const Cookie = (props) => {


    return (
        <CookieConsent
            debug={true}
            onAccept={() => {
                props.onChange()
                // setAcceptCookie(true)
                // setAcceptCookie((state) => {
                //     console.log(state)
                //     return state
                // })
            }}
            location="bottom"
            hideOnAccept='true'
            buttonText="I understand"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ backgroundColor: "#11D3BC", borderRadius: '5px', color: "white", fontSize: "13px" }}
            expires={150}
        // enableDeclineButton
        // onDecline={() => {
        //     setAcceptCookie(false)
        // }}
        >
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>

    )
}

export default Cookie
