

const data = {
    head: {
        title: "Privacy Policy",
        header1: "CODECA EDUCATIONAL TECHNOLOGY AND SERVICES INC.",
        header2: 'PRIVACY STATEMENT',
        updatedDate: 'Last Updated: July 3, 2019',
        description: "Codeca Educational Technology and Services Inc. (<b>“Codeca”</b>, <b>“we”</b> or <b>“us”</b>) is committed to protecting your privacy and safeguarding your personal information. The purpose of this privacy statement is to inform you about our privacy practices, including how we collect, use and disclose your personal information.<br/><br/>This privacy statement relates to all of our activities, unless we have provided you with a separate privacy statement for a particular product, service or activity.<br/><br/>Please review this privacy statement carefully. By submitting your personal information to us, by registering for or using any of the services we offer, by using our website, or by voluntarily interacting with us, you consent to our collecting, using and disclosing your personal information as set out in this privacy statement, as revised from time to time. If you do not consent to the collection, use, and disclosure of your personal information in accordance with this privacy statement, you may not use the our products or services.<br/><br/>If you are the parent or guardian or other individual with legal authority to provide consent on behalf of a user of our products or services who is under the legal age of majority in their jurisdiction, then you consent on behalf of that user to the collection, use, and disclosure of their personal information in accordance with this privacy statement.",
    },
    table:
        `<table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 style='border-collapse:collapse'>
    <tr>
     <td width=68 valign=top style='width:68.4pt;padding:0cm 5.4pt 0cm 5.4pt'>
     <p class=TableTableau><span lang=EN-CA>Address: </span></p>
     </td>
     <td width=410 valign=top style='width:410.4pt;padding:0cm 5.4pt 0cm 5.4pt'>
     <p class=TableTableau><span lang=EN-CA>Attention: Tomoko Okochi, Privacy
     Officer</span></p>
     <p class=TableTableau><span lang=EN-CA style='color:black'>Codeca Educational
     Technology and Services Inc.</span><span lang=EN-CA><br>
     c/o Fasken Martineau DuMoulin LLP<br>
     550 Burrard Street, Suite 2900<br>
     Vancouver, BC  V6C 0A3</span></p>
     <p class=TableTableau><span lang=EN-CA>Attention: Houtan Mashinchi</span></p>
     </td>
    </tr>
    
    <tr>
     <td width=68 valign=top style='width:68.4pt;padding:0cm 5.4pt 0cm 5.4pt'>
     <p class=TableTableau><span lang=EN-CA>By e-mail:</span></p>
     </td>
     <td width=410 valign=top style='width:410.4pt;padding:0cm 5.4pt 0cm 5.4pt'>
     <p class=TableTableau><span lang=EN-CA>info@codeca.io</span></p>
     </td>
    </tr>
   </table>`,
    contentList: {
        title: "WHAT’S IN THIS PRIVACY POLICY?",
        detail: [
            {
                title: "Meaning of Personal Information",
                description: '"<b>Personal information</b>" means information about an identifiable individual. This information may include, but is not limited to, your name, mailing address, e-mail address and telephone number.<br/><br/>Personal information does not include any business contact information that is solely used to communicate with you in relation to your employment, business or profession, such as your name, position name or title, work address, work telephone number, work fax number or work electronic address.<br/><br/>Personal information also does not include information that has been anonymized or aggregated in such a way that there is no serious possibility it can be used to identify an individual, whether on its own or in combination with other information.'
            },
            {
                title: "Your Consent to Collection, Use and Disclosure",
                description: 'We collect, use and disclose your personal information with your consent or as permitted or required by law.  How we obtain your consent (i.e. the form we use) will depend on the circumstances, as well as the sensitivity of the information collected. Subject to applicable laws, your consent may be express or implied, depending on the circumstances and the sensitivity of the personal information in question. If you choose to provide personal information to us, we assume that you consent to the collection, use and disclosure of your personal information as outlined in this privacy statement.<br/><br/>Typically, we will seek your consent at the time your personal information is collected. Where we want to use your personal information for a purpose not previously identified to you at the time of collection, we will seek your consent prior to our use of such information for this new purpose.<br/><br/>You may withdraw your consent to our collection, use or disclosure of your personal information at any time by contacting us using the contact information in the “Contact Us” section below.  However, before we implement the withdrawal of consent, we may require proof of your identity. In some cases, withdrawal of your consent may mean that we will no longer be able to provide certain products or services.<br/><br/>If you provide personal information about another individual to us, it is your responsibility to obtain the consent of that individual to enable us to collect, use and disclose his or her information as described in this privacy statement.'
            },
            {
                title: 'Personal Information We Collect',
                description: `<p class=MsoBodyText><span lang=EN-CA>The personal information we collect is
                generally in one or more of the following categories.</span></p>
                
                <p class=MsoBodyText style='margin-top:11.0pt;margin-right:0cm;margin-bottom:
                11.0pt;margin-left:36.0pt;text-indent:-36.0pt'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><i><u><span lang=EN-CA>Products and Services.</span></u></i><span lang=EN-CA>  For individuals who use our products and services, we may collect
                information from you or from your use of our products or services. The type of
                information we may collect may vary depending on the specific product or
                service offered, as set out below: </span></p>
                
                <p class=Bullet2 style='margin-top:0cm;margin-left:50px;'><span lang=EN-CA style='font-family:
                Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span lang=EN-CA>C3D</span></b><span lang=EN-CA>: For our
                coding teaching tool C3D, the information we may collect will depend on whether
                you are an “Account Holder” or an “End User”.</span></p>
                
                <p class=Bullet3 style='margin-left:150px;'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>Account Holders are individuals or organizations
                that </span><span lang=EN-CA>register directly with us for the C3D product,
                such as school boards, schools and coding camps that wish to provide C3D to
                their students and teachers. </span><span lang=EN-CA>For Account Holders, we
                collect information you provide in connection with the creation and management
                of your account, such as your name, e-mail address and other contact
                information.</span></p>
                
                <p class=Bullet3 style='margin-left:150px;'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>End Users are individuals authorized by an
                Account Holder to use C3D under the Account Holder’s account, usually students
                or teachers.  As End Users may include children, we limit the collection of
                personal information from End Users to that which is required to provide C3D
                and avoid collecting personal information where possible.  For End Users, we
                may collect (i) the End User’s username (which we require be a non-identifying
                alias); (ii) any code or other user data inputted by the End User through their
                use of C3D; or (iii) if the End User selects the option to link their Google
                account to C3D, their Google account username.</span></p>
                
                <p class=Bullet2 style='margin-left:50px;'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>Information we automatically collect: we collect
                log data and usage information relating to your use of our products and
                services.</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><i><u><span lang=EN-CA>Website.</span></u></i><span lang=EN-CA> 
                For individuals who visit our website located at </span><span lang=EN-CA>https://www.c3d.io/
                </span><span lang=EN-CA>or any of our related websites, including any private
                domains provided to Account Holders for access to C3D (collectively, “<b>our
                website</b>”), we may collect information from you or from your activities on
                the website.</span></p>
                
                <p class=Bullet2 style='margin-left:50px;'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>Like most websites and other Internet services,
                we may collect certain technical and device information about your use of our
                website.  Such information may include your Internet protocol address,
                information about your device, browser and operating system, and the date and
                time of your visit.</span></p>
                
                <p class=Bullet2 style='margin-left:50px;'><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>We may also use “cookies” or enlist third party
                services which use cookies to track your preferences and activities on our
                website.  Cookies are small data files transferred to your computer’s
                hard-drive by a website. They keep a record of your preferences, making your
                subsequent visits to the site more efficient. Cookies may store a variety of
                information, including the number of times that you access a site, your
                registration information and the number of times that you view a particular
                page or other item on the site.  The use of cookies is a common practice
                adopted by most major sites to better serve their users. Most browsers are
                designed to accept cookies, but they can be modified to block cookies. See your
                browser’s help files for more information. You should note, however, that
                without cookies some of our website’s functions may not be available.</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><i><u><span lang=EN-CA>Other Interactions.</span></u></i><span lang=EN-CA>  </span><span lang=EN-CA>For </span><span lang=EN-CA>individuals
                who </span><span lang=EN-CA>otherwise </span><span lang=EN-CA>interact with us,
                </span><span lang=EN-CA>whether in person, by phone or</span><span lang=EN-CA>
                email, </span><span lang=EN-CA>through social media</span><span lang=EN-CA> or
                otherwise</span><span lang=EN-CA>, including individuals who might be
                interested in acquiring our products or services, who sign-up to receive
                newsletters or other communications, or who respond to surveys and
                questionnaires, we may collect information that you provide to us during these
                interactions. This information may include your name, e-mail address and other
                contact information.</span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>We do not collect payment card
                information. If you make an online payment using a payment card, such as a
                credit card or debit card, you are connected directly to our online payment
                processing service provider and your payment card information is collected and
                processed by that service provider.</span></p>`
            },
            {
                title: 'How We Use Your Personal Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>We may use your personal information and
                other information for purposes such as:</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to provide you with our products and services
                and to support your use of our products and services;</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to contact you regarding our products and
                services;</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to monitor and improve our products and
                services, and to develop new products and services;</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to analyze the needs and activities of our
                customers to help us better serve them;</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span>to conduct research and analysis related to our business,
                products and services;</p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to respond to inquiries and other requests; </span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to collect opinions and comments in regard to
                our products and services;</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to provide you with information that we think
                may be of interest, including in regards to our products and services; and</span></p>
                
                <p class=Bullet1><span lang=EN-CA style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span lang=EN-CA>to investigate legal claims.</span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>We may use your personal information and
                other information for purposes for which we have obtained your consent, and for
                such other purposes as may be permitted or required by applicable law.</span></p>
                
                <p class=MsoBodyText>We do not use the information we collect to provide
                advertising of third party products and services or targeted advertising of
                Codeca products and services across third party websites or service offerings.</p>
                
                <p class=MsoBodyText>For End Users of C3D, we only use your personal
                information for the purpose of providing you with C3D, to monitor and improve
                C3D, or as otherwise permitted or required by law. We do not use your personal
                information for marketing purposes of any kind. </p>
                `
            },
            {
                title: 'How We Share Your Personal Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>We rely on third party services providers
                to perform a variety of services on our behalf, </span>such as e-commerce
                providers, payment card processers, telephone and technical support providers,
                hosting, data storage and processing service providers, and research and
                analytics providers. </p>
                
                <p class=MsoBodyText>If we provide your information to service providers, then
                we require that the service providers maintain the confidentiality of your
                personal information and keep your personal information secure.  We also
                require that they only use your personal information for the limited purposes
                for which it is provided.  When our service providers no longer need your
                personal information for those limited purposes, we require that they dispose
                of the personal information. In some circumstances, we may permit our service
                providers to retain aggregated, anonymized or statistical information that does
                not identify you.  We do not authorize the service providers to disclose your
                personal information to unauthorized parties or to use your personal
                information for their direct marketing purposes.  If you would like more
                information about our service providers, <span lang=EN-CA>please contact us
                using the contact information in the “Contact Us” section below</span>.</p>
                
                <p class=MsoBodyText>Additionally, we may use and disclose your information
                when we believe such use or disclosure is permitted, necessary or appropriate:
                (a) under applicable law, including laws outside your country of residence; (b)
                to comply with legal process; (c) to respond to requests from public and
                government authorities, including public and government authorities outside
                your country of residence; (d) to enforce the terms of the agreements for our
                products and services; (e) to protect our operations or those of any of our
                affiliates or subsidiaries; (f) to protect our rights, privacy, safety or
                property, and/or those of our affiliates, you or others; and (g) to allow us to
                pursue available remedies or limit the damages that we may sustain.  In
                addition, we may transfer your personal information and other information to a
                third party in the event of any reorganization, merger, sale, joint venture,
                assignment, transfer or other disposition of all or any portion of our
                business, brands, affiliates, subsidiaries or other assets.</p>
                
                <p class=MsoBodyText><span lang=EN-CA>If we otherwise intend to disclose your
                personal information to a third party, we will identify that third party and
                the purpose for the disclosure, and obtain your consent. </span></p>`
            },
            {
                title: 'Opting Out of Communications',
                description: `<p class=MsoBodyText>If you no longer want to receive marketing-related emails
                from us, you may opt-out of receiving marketing-related emails by clicking the
                “unsubscribe” link at the bottom of any email you receive from us, or, if you
                created an online account when you registered to receive our emails, you may
                log-in to your account and make changes to your communication preferences.  <span lang=EN-CA>You may also opt-out by contacting us directly using the contact
                information in the “Contact Us” section below. </span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>We will endeavour to respond to your
                opt-out request promptly, but we ask that you please allow us a reasonable time
                to process your request.  Please note that if you opt-out from receiving
                marketing-related emails, we may still need to send you communications about
                your use of our products or services, or other matters.</span></p>`
            },
            {
                title: 'Retention of Personal Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>We will use, disclose or retain your
                personal information only for as long as necessary to fulfill the purposes for
                which that personal information was collected and as permitted or required by
                law. </span></p>`
            }, {
                title: 'Information Security',
                description: `<p class=MsoBodyText><span lang=EN-CA>We have implemented physical,
                organizational, contractual and technological security measures with a view to
                protecting your personal information and other information from loss or theft,
                unauthorized access, disclosure, copying, use or modification.  We have taken
                steps to ensure that the only personnel who are granted access to your personal
                information are those with a business ‘need-to-know’ or whose duties reasonably
                require such information.</span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>Despite the measure outlined above, no
                method of information transmission or information storage is 100% secure or
                error-free, so we unfortunately cannot guarantee absolute security.  If you
                have reason to believe that your interaction with us is no longer secure (for
                example, if you feel that the security of any information that you provided to
                us has been compromised), please contact us immediately using the contact
                information in the “Contact Us” section below.</span></p>`
            },
            {
                title: 'Accessing and Updating Your Personal Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>We will take steps to ensure that your
                personal information is kept as accurate, complete and up-to-date as reasonably
                necessary.  We will not routinely update your personal information, unless such
                a process is necessary.  We expect you, from time to time, to supply us with
                updates to your personal information, when required.</span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>You may make a written request to review
                any personal information about you that we have collected, used or disclosed,
                and we will provide you with any such personal information to the extent
                required by law.  You may also challenge the accuracy or completeness of your
                personal information in our records. If you successfully demonstrate that your
                personal information in our records is inaccurate or incomplete, we will amend the
                personal information as required.</span></p>
                
                <p class=MsoBodyText><span lang=EN-CA>We may require that you provide
                sufficient identification to fulfill your request to access or correct your
                personal information.  Any such identifying information will be used only for
                this purpose.</span></p>`
            },
            {
                title: 'International Transfer and Storage of Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>Your personal information may be stored
                and processed in any country where we have facilities or in which we engage
                third party service providers. As a result, your personal information may be
                transferred to countries outside your country of residence, which may have
                different data protection rules than in your country.  While such information
                is outside of your country, it is subject to the laws of the country in which
                it is located, and may be subject to disclosure to the governments, courts or
                law enforcement or regulatory agencies of such other country, pursuant to the
                laws of such country.</span></p>`
            },
            {
                title: 'Third Party Websites and Services',
                description: `<p class=MsoBodyText><span lang=EN-CA>This privacy statement applies only to
                our products and services.  This privacy statement does not extend to any
                websites or products or services provided by third parties.  We do not assume
                responsibility for the privacy practices of such third parties, and we
                encourage you to review all third party privacy policies prior to using third
                party websites or products or services.</span></p>`
            },
            {
                title: 'Children’s Information',
                description: `<p class=MsoBodyText><span lang=EN-CA>Our products and services may only be
                used by children under the age of 13 with the express consent of their parent
                or guardian or other individual with legal authority to provide such consent
                (each a “Legal Guardian”).  We do not knowingly collect personal information
                from children under the age of 13 without the express consent of their Legal
                Guardian, and children under the age of 13 should not use our products and
                services and should not provide us with their personal information without the
                express consent of their Legal Guardian.</span></p>`
            },
            {
                title: 'Privacy Statement Updates',
                description: `<p class=Headingext1><span lang=EN-CA>This privacy statement is current as of
                the “updated” date which appears at the top of this page.  We may modify this
                privacy statement from time to time.  When changes are made to this privacy
                statement they will become immediately effective when published in a revised
                privacy statement posted on our website unless otherwise noted.  We may also
                communicate the changes through our services or by other means.  By submitting
                your personal information to us, by registering for or using any of the
                services we offer, by using our website, or by voluntarily interacting with us
                after we publish or communicate a notice about the changes to this privacy
                statement, you consent to our collecting, using and disclosing your personal
                information as set out in the revised privacy statement.</span></p>`
            },
            {
                title: 'Contact Us',
                description: `<p class=MsoBodyText><span lang=EN-CA>All comments, questions, concerns or
               complaints regarding your personal information or our privacy practices should
               be sent to our Privacy Officer as follows:</span></p>`
            }
        ],


    },

};

export default data;
