import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import ReactPlayer from "react-player"
import classNames from 'classnames'


const useStyles = makeStyles((theme, props) => ({
    root: {
        textAlign: 'center',
        justifyContent: "center",
        padding: '1%',
        margin: '1%',
        width: '80%',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        }
    },
    title: {
        marginBottom: '4%'
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
    },

    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
    }
}));

const Video = props => {
    const styling = props.styleVideo
    const classes = useStyles();
    const videoClass = classNames(styling, classes.root)
    return (
        <Grid className={videoClass}>
            <h2 className={classes.title}>
                {props.header}
            </h2>


            <div className={classes.playerWrapper}>
                <ReactPlayer
                    className={classes.reactPlayer}
                    url={props.link}
                    width='100%'
                    height='100%'
                    controls={true}
                />
            </div>

        </Grid>
    )
}

export default Video