import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import { Image, canUseWebP } from "react-img-webp";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '2rem',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    img: {
        maxWidth: 500,
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    txt: {
        display: 'inline-block',
        maxWidth: 500,
        margin: '5rem 1rem',
        [theme.breakpoints.down('md')]: {
            margin: '1rem 1rem 2.2rem',
            //width: '60%',
            // textAlign: 'left'
        },
        [theme.breakpoints.down('1147')]: {

            textAlign: 'left'
        }
    }
}));

const LeftImgEmpower = props => {
    const classes = useStyles();

    return (

        <Grid container className={classes.root}>
            <div xs={6} className={classes.img}>
                <Image src={props.img} webP={props.imgWebp} className={classes.image} alt="Screenshot of the feature" />
                {/* <img src={props.imgWebp} className={classes.image} alt="Screenshot of the feature" /> */}
            </div>
            <div xs={6} className={classes.txt}>
                <h3 style={{ color: '#FC5185' }} variant="h5">{props.title}</h3>
                <p>{props.body}</p>
            </div>
        </Grid>

    )
}

export default LeftImgEmpower