import React from 'react'
import Grid from "@material-ui/core/Grid"
import GeneralBtn from "../../parts/elems/GeneralBtn"
import { makeStyles } from '@material-ui/core/styles';
import data from "../../../data/landing.js";
import { useHistory } from "react-router-dom";

const styles = makeStyles((theme) => ({
    root: {
        marginTop: '3rem !important',
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "1rem",
    },
    btncontainer: {
        marginTop: "2rem",
        marginBottom: "5rem",
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
    },
    ebtn: {
        backgroundColor: "#FC5185 !important",
        "&:hover": {
            backgroundColor: "#bf4d6f !important",
        }
    },
    pbtn: {
        backgroundColor: "#11D3BC !important",
        [theme.breakpoints.up('sm')]: {
            marginLeft: '5rem !important'
        },
    },
    text: {
        [theme.breakpoints.up('md')]: {
            width: "40vw",
        },
        width: "80vw",
        textAlign: "center",
    },
    title: {
        [theme.breakpoints.up('md')]: {
            width: "30vw",
        },
        width: "70vw",
        textAlign: "center",
    },
}))




const Linkto = (props) => {
    const classes = styles()
    const history = useHistory();
    function teachersLink() {
        history.push("/school");
    }

    function parentsLink() {
        console.log("in parents link")
        history.push("/parents");
    }

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{props.header}</h2>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: props.para }}></div>
            <Grid item className={classes.btncontainer} >

                <GeneralBtn stylebtn={classes.ebtn} label={data.topEducation.button1} link={teachersLink} />
                <GeneralBtn stylebtn={classes.pbtn} label={data.topEducation.button2} link={parentsLink} />
            </Grid>
        </div>
    )
}

export default Linkto