import React, { useEffect } from 'react'

import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import GeneralBtn from "../parts/elems/GeneralBtn";
import { isWebpSupported } from '../../utils/isWebpSupport';
import imgWebp from "../../assets/java-teaching-tool-coding.webp";
import img from "../../assets/java-teaching-tool-coding.png";
import Button from '@material-ui/core/Button';



let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        height: "100%",
        width: "100%",
        marginBottom: "3rem",
        [theme.breakpoints.up('md')]: {
            // height: '500px',
        }
    },
    container: {
        textAlign: 'center',
        margin: '0 auto',
        padding: '10rem 0'
    },
    header: {
        position: 'relative',
        color: "white",
        textAlign: "center",
        margin: '0 1rem'
    },
    desc: {
        position: 'relative',
        maxWidth: '450px',
        margin: "3rem 0",
        textAlign: "center",

    },
    btn: {

        padding: "1vw 2vw !important",
        boxSizing: 'border-box',
        fontWeight: '900',
        marginTop: "3%",
        marginRight: "0 !important",
        textTransform: "none !important",
        fontSize: '16px',
        position: 'relative',
        backgroundColor: "#11D3BC",
        [theme.breakpoints.down('sm')]: {
            borderRadius: "5px",
            width: "250px",
            maxWidth: "290px",
            maxHeight: "50px",
            padding: "3vw 4vw !important",
        },
        // [theme.breakpoints.down('340px')]: {
        //     width: "230px"
        // },
        borderRadius: "10px",
        minWidth: "210px",
        width: "auto",

    },

}));


const Codinglessons = () => {
    const classes = useStyles()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleClick = () => {
        window.open('https://wp.c3d.io/solution/');
    }

    return (
        <div>

            <Grid item xs={12} className={classes.root} style={{
                background: `linear-gradient(
            rgba(0, 0, 0,.5), 
            rgba(0, 0, 0,.5)
        ),url(${isWebpSupported() ? imgWebp : img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right'
            }}>
                <Grid item xs={12} className={classes.container}>
                    <ThemeProvider theme={theme}>
                        <h1 className={classes.header}>Lesson Module Solutions</h1>
                        <h2 className={classes.header}>Having issues proceeding with lessons?
Here are solutions for each step for lesson modules.</h2>
                        <Button className={classes.btn}>
                            <a style={{ textDecoration: 'none', color: 'white' }} href="https://wp.c3d.io/solution/">Click to Find Solution</a>
                        </Button>
                    </ThemeProvider>

                </Grid>
            </Grid>



        </div>


    )
}

export default Codinglessons