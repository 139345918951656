import React from 'react'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { withStyles } from "@material-ui/core/styles"

const styles = (theme, props) => ({
    root: {
        display: 'flex',
        justifySelf: 'center',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '400px'
        },

    },
    container: {
        flex: 1,
    },
    personName: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        color: '#FC5185'
    },
    personImage: {
        display: "block",
        maxWidth: "180px",
        margin: "1rem auto",
        borderRadius: "50rem"
    },
    personContainer: {
        "-webkit-box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        "-moz-box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        "box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        padding: ".8rem",
        margin: "1rem",

    },
    description: {
        margin: "1rem 0.5rem"
    }
})

const PersonCard = ({ name, imgURL, position, description, classes }) => {
    return (
        <Grid container item className={classes.root}>

            <Paper className={classes.personContainer}>

                <div className={classes.container}>
                    <img className={classes.personImage} src={imgURL} alt={name} />
                    <h2
                        className={classes.personName}
                        align="center">
                        {name}
                    </h2>
                    <h3 align="center">
                        {position}
                    </h3>
                    <div align="left" className={classes.description} dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>

            </Paper >
        </Grid>
    );
}

export default withStyles(styles)(PersonCard);