import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"


const useStyles = makeStyles({
    root: {
        minWidth: 300,
        maxWidth: '380px !important',
        margin: '1%',
        padding: '2%',
        textAlign: 'center',
        // height: 400
    },
    title: {
        fontSize: 14,
    },

});

const Feature = props => {
    const classes = useStyles();

    return (
        <Grid item xs className={classes.root}>
            <img src={props.img} alt="Feature icon" />
            <h3>
                {props.title}
            </h3>
            <p>
                {props.body}
            </p>

        </Grid>
    )
}

export default Feature