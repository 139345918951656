import React, { Component } from "react";
import axios from 'axios';
import Article from '../parts/cards/Article'
import Grid from "@material-ui/core/Grid"
import HeroCenterText from "../parts/HeroCenterText";
import cover from "../../assets/teach-coding-easy.jpg";
import coverWebp from "../../assets/teach-coding-easy.webp";

const data = {
  hero: {
    header: "News and Articles",
    link: "",
    img: cover,
    imgWebp: coverWebp,
    alt: "cover",
    id: "teacher"
  }
}

export class Articles extends Component {
  state = {
    articles: [],
    isLoaded: false
  }

  componentDidMount() {
    axios.get('https://wp.c3d.io/wp-json/wp/v2/posts')
      .then(res => this.setState({
        articles: res.data,
        isLoaded: true
      }))
      .catch(err => console.log(err))
  }
  render() {
    // console.log(this.state);
    const { articles, isLoaded } = this.state;
    return (
      <Grid container style={{ justifyContent: "center" }}>
        <HeroCenterText {...data.hero} />
        {articles.map(a =>
          <Article key={a.id} article={a} />
        )}

      </Grid>
    )
  }
}
export default Articles

