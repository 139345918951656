import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames'

const useStyles = makeStyles((theme, props) => ({
    btn: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: "5px !important",
            width: "250px !important",
            maxWidth: "290px !important",
            maxHeight: "50px !important"
        },
        // [theme.breakpoints.down('340px')]: {
        //     width: "230px"
        // },
        borderRadius: "10px !important",
        minWidth: "210px !important",
        width: "auto !important",
        // height: "60px",

    },
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: "0.5vw 4vw !important",
        },
        padding: "0.5vw 2vw !important",
        boxSizing: 'border-box !important',
        fontWeight: '900 !important',
        marginTop: "3% !important",
        marginRight: "10px !important",
        textTransform: "none !important",
        fontSize: '16px !important'
    }
}));

const GeneralBtn = (props) => {
    // const [disable] = setState(props.disable)
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const styling = props.stylebtn
    const style = useStyles()
    const btnClass = classNames(styling, style.btn)
    console.log(btnClass);
    // if (props.disable) {
    //     setDisable(props.disable)
    // }




    return (
        <Button
            // type={props.type}
            aria-label={props.label}
            aria-labelledby={props.label}
            disabled={props.disableBtn}
            classes={{ root: style.root }}
            className={btnClass} variant="contained" onClick={props.link}>
            {props.label}
        </Button>
    )
}

export default GeneralBtn




