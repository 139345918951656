import React, { useRef, useEffect } from 'react'

// import EngageParents from "../parts/EngageParents";
import HeroLeftText from '../parts//HeroLeftText'
import LearnToCode from "../parts/parents/LearnToCodeGrid";
import MonthlySubscription from "../parts/parents/MonthlySubscription";
import ChildLearn from "../parts/parents/ChildLearn";
// import SignUpPromotion from "../parts/SignUpPromotion";
import ThreeSteps from "../parts/parents/ThreeSteps";
import ProjectGallery from "../parts/parents/ProjectGallery";
import OnlineClass from "../parts/parents/OnlineClassOffer";
import Customer from "../parts/parents/Customer"
import FAQ from "../parts/FAQ";
import data from '../../data/parents';
import Quote from '../parts/Quote'
import SeeVideo from '../parts/parents/SeeVideo'

import AgeGroups from "../parts/parents/AgeGroups.js";

const scrollToRefObject = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })


const Parents = props => {
  const learnCode = useRef(LearnToCode)
  const subscription = useRef(MonthlySubscription)
  const learnCodeScroll = () => scrollToRefObject(learnCode)
  const subscriptionScroll = () => scrollToRefObject(subscription)
  const handleSignUpClick = () => {
    window.open('https://canada.c3d.io/signup.html/');
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {/* <EngageParents/> */}
      <HeroLeftText {...data.hero} signupLink={() => handleSignUpClick()} />

      {/* online course section 
      <div class="summercamp" style={{textAlign: "center", backgroundColor: "#FC5185", color:"white", borderRadius:"50px", width:"50vw", margin:"1rem auto", padding:"5px"}}>
        <a href="https://wp.c3d.io/product-category/the-urban-camp/" style={{color:"white", textDecoration: "none"}}><h2>Online Classes</h2></a>
      </div>
       end of online course section, comment out this section when no classes */}

      <div ref={learnCode}>
        <LearnToCode />
      </div>
      <SeeVideo {...data.video} />
      <div ref={subscription}>
        <MonthlySubscription />
      </div>
      <ChildLearn />
      <ThreeSteps />
      <ProjectGallery />
      <OnlineClass />
      <div style={{
        textAlign: 'center', display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }} >
        <Quote {...data.quote} />
      </div>
      <Customer />
      <FAQ {...data.Faq} />
      <AgeGroups subscriptionLink={subscriptionScroll} />

    </div>
  );
};

export default Parents