import React from "react";
import data from "../../../data/landing.js";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import GeneralBtn from "../../parts/elems/GeneralBtn"
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Sticky from '../Sticky.js';
import ExampleStuckContent from '../ExampleStuckContent.js';
import { Context } from "react-img-webp";
import { isWebpSupported } from '../../../utils/isWebpSupport';

const sides = {
    top: 10, // Sticks when it scrolls past the top edge
    bottom: 0, // Sticks when it scrolls past the bottom edge
    left: 10, // Sticks 10px from the left edge
    right: -20, // Sticks 20px past the right edge (useful for content that should stick only when it's fully out of the frame)
};


const styles = makeStyles((theme, prop) => ({
    root: {
        width: "100%",
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",
        position: "relative",
        [theme.breakpoints.up('sm')]: {
            height: '500px',
        },
        [theme.breakpoints.up('md')]: {
            height: '550px',
        },
        // [theme.breakpoints.up('lg')]: {
        //     height: '700px',
        // }
    },
    content: {
        textAlign: 'center',
        maxWidth: '80vw',
        // margin: '10% auto',
        margin: '5% auto !important',
        [theme.breakpoints.up('sm')]: {
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%,-50%)',
            margin: '5rem auto',
            maxWidth: '60vw',
            textAlign: "center",
        },
        // [theme.breakpoints.up('lg')]: {
        //     // top: '20%'
        // }
    },
    text: {
        //width: "90%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.3rem",
        color: "white",
        [theme.breakpoints.up('md')]: {
            width: "50vw",

        },
    },
    title: {
        //width: "90%",
        textAlign: "center",
        justifyContent: "center",
        // fontSize: "3vw",
        color: "white",
        display: "inline-block",
        [theme.breakpoints.up('md')]: {
            width: "50vw",

        },
    },
    img: {
        width: '100%',
        height: '100%',
        minWidth: '100%',
        minHeight: '100%',
        position: 'relative',
        objectFit: 'scale-down',
        opacity: '0.4'
    },
    btncontainer: {
        marginTop: "2rem",
    },
    ebtn: {
        margin: '0 2rem !important',
        backgroundColor: "#FC5185 !important",
        "&:hover": {
            backgroundColor: "#bf4d6f !important",
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0.5rem 0 !important',
        },
    },
    pbtn: {
        backgroundColor: "#11D3BC !important",
        margin: '0 2rem !important',
        [theme.breakpoints.down('md')]: {
            margin: '1rem 2rem !important',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0.5rem 0 !important',
        },
    },

}))


const TopEducation = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = styles(isSmallScreen)

    const history = useHistory();

    function teachersLink() {
        history.push("/school");
    }

    function parentsLink() {
        console.log("in parents link")
        history.push("/parents");
    }

    return (

        <Grid className={classes.root} style={{
            background: `linear-gradient(
                rgba(0, 0, 0,.5), 
                rgba(0, 0, 0,.5)
            ),url(${isWebpSupported() ? data.topEducation.imgWebp : data.topEducation.img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
        }}>
            <Grid item className={classes.content} style={{ textAlign: "center" }}>
                {/* remove colour once picture is replaced */}
                <h1 className={classes.title}>{data.topEducation.header}</h1>
                {data.topEducation.para.map((item, index) => (
                    <p key={index} className={classes.text} style={{ paddingTop: index === 1 ? "2.5vh" : "0vh" }}>{item}</p>
                ))}
                <Sticky sides={sides}>
                    <ExampleStuckContent>
                        <Grid item className={classes.btncontainer} >
                            <GeneralBtn stylebtn={classes.ebtn} label={data.topEducation.button1} link={teachersLink} />
                            <GeneralBtn stylebtn={classes.pbtn} label={data.topEducation.button2} link={parentsLink} />
                        </Grid>
                    </ExampleStuckContent>
                </Sticky>
            </Grid>
        </Grid >



    )
}

export default TopEducation