import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import data from "../../../data/parents.js"
import CardActions from '@material-ui/core/CardActions';
import GeneralBtn from "../elems/GeneralBtn";


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        margin: '4rem',
        padding: '0 1% 1% 1%',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            margin: '2rem 1rem 2rem 1rem',
        }

    },
    title: {
        // marginBottom: '2%',
        // color: '#11D3BC',
    },
    content: {
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
        padding: '3% 8% 2% 8%',
    },
    btn: {
        marginTop: '5%'
    },
    stylebtn: {
        color: '#11D3BC',
        backgroundColor: 'white',
        border: '1px solid #11D3BC'
    },
    button: {
        margin: "3vw",
        padding: "1vw 3vw",
        borderRadius: "0.5vw",
        color: "#FFFFFF",
        backgroundColor: "#FC5185",
        fontSize: "1.25vw",
        border: "none",
        position: "absolute",
    },
    sbtn: {
        color: "#FFFFFF",
        backgroundColor: "#FC5185",
        justifyContent: "center",
        "&:hover": {
            backgroundColor: "#bf4d6f",
        }
    },
    company: {
        "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.25)",
        borderRadius: "0.7vw",
        display: "flex",
        "flex-direction": "column",
        minWidth: 250,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            margin: "0 5vw",
        }
    },
    recommend: {
        backgroundColor: "#333333",
        borderRadius: "0.7vw 0.7vw 0 0",
        color: "#FFFFFF",
        minWidth: 250,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: "5vh"
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "0",
            margin: "0 5vw",
        }
    },
    center: {
        display: "flex",
        justifyContent: "center",
        marginTop: '30px',
    },
}));

const IndividualPlan = props => {
    const classes = useStyles();
    const handleSignUpClick = () => {
        window.open('https://canada.c3d.io/signup.html')
    }

    return (
        <Grid className={classes.root} >

            <Grid container sx={8}>
                <Card className={classes.content} variant="outlined">
                    <CardContent>
                        <h2 className={classes.title} style={{ color: '#11D3BC' }}>
                            {props.title}
                        </h2>
                        <p>
                            {props.description}
                        </p>
                    </CardContent>
                    <div style={{ textAlign: "left", padding: '3% 8% 2% 8%', }}>
                        {props.details}
                    </div>
                    <Grid container direction="row" className={classes.center}>
                        {data.MonthlySub.programs.map((item, index) => (
                            <Grid key={index} item xs={12} md={6} style={{ textAlign: "center" }}>
                                <h3 className={classes.recommend} style={{ visibility: index === 1 ? "visible" : "hidden" }}>Recommended</h3>
                                <Card className={classes.company} style={{ borderRadius: index === 1 ? "0 0 0.7vw 0.7vw" : "0.7vw" }}>
                                    <CardContent>
                                        <img src={item.img} alt="program" />
                                        <h3>{item.time}</h3>
                                    </CardContent>
                                    <CardActions style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        <GeneralBtn stylebtn={classes.sbtn} label={item.button} link={() => handleSignUpClick()} />
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default IndividualPlan