
// import cover from "../assets/java.png";
// import jason from "../assets/Team/jason.jpg";
import coverWebp from "../assets/java-teaching-tool-coding.webp";
import cover from "../assets/java-teaching-tool-coding.png";
import chloe from "../assets/testimonial/chloe.png";
import touch from '../assets/features/touch.png'
import homework from '../assets/features/homework.png'
import marketing from '../assets/features/marketing.png'
import password from '../assets/features/password.png'
import school from '../assets/features/school.png'
import student from '../assets/features/student.png'
import contentWebp from '../assets/coding-lesson-plans.webp'
import content from '../assets/coding-lesson-plans.png'


const data = {
    hero: {
        header: "Transformative Java teaching tool for computer science curriculum",
        description: "C3D Java helps teachers deliver the AP Computer Science curriculum in an engaging way. This Java tool transforms Java lessons into a fun VR coding session.",
        imgWebp: coverWebp,
        img: cover,
        alt: "cover",
        id: "java"
    },
    features:
    {
        header: "C3D Java: Transform your Computer Science Class",
        body: "C3D Java is a transformative java platform for your computer science curriculum. It has features to change the way you teach and how students engage with Java!",
        lists: [
            {
                img: touch,
                title: "Increases engagement",
                body: "Increases engagement with visual output instead of console in computer science curriculum material"
            },
            {
                img: marketing,
                title: "Compatible with your course",
                body: "C3D Java is suitable for both AP CS A high school computer science curriculum and first-year post-secondary courses"
            },
            {
                img: homework,
                title: "Easy to access",
                body: "C3D Java is a free, fast and secure tool to teach and learn Java"
            },
            {
                img: student,
                title: "No additional software",
                body: "C3D Java runs right in your browser. No need to install any additional software! "
            },
            {
                img: password,
                title: "Uses 3D visual output",
                body: "Shifts from a console output to a 3D visual output to increase student learning outcomes as students related to 3D visual content"
            },
            {
                img: school,
                title: "Easy to use",
                body: "The interface is easy to use and makes it easy for students to understand the concepts leading to higher learning outcomes"
            }
        ]
    },
    video: {
        header: "See how it works",
        link: "https://www.youtube.com/watch?v=lABF1vJ-aQ4&feature=emb_logo"
    },
    getStart: {
        title: "Ready to get started?",
        subtitle: "Sign up now for C3D Java today",
        btnLabel: "Get Started"
    },
    plans: {
        header: "Lesson Plans",
        description: "In addition to free-to-use C3D Java tool, we offer teachers computer science lesson plans ",
        details: [
            {
                title: "Comprehensive",
                desc: "Designed to cover CS and intro to Java/Object-Oriented Programming college courses"
            },
            {
                title: "Engaging",
                desc: "Students will be hooked to the VR preview window and 3D story telling. Fun exercises included!"
            },
            {
                title: "Time-saving",
                desc: "All solutions are available as code snippet. You can use the lessons in your class right away"
            },
            {
                title: "Quick Marking",
                desc: "You can load students assignments on a browser in a matter of a seconds"
            }
        ]
    },
    contents: {
        imgUrl: content,
        imgUrlWebp: contentWebp,
        header: "Table of Contents",
        items: ["1. Classes", "2. Objects", "3. Conditions", "4/5. Arrays", "6/7. ArrayLists", "8. Inheritance", "9. Fun Exercise - Feed the zombies 1", "10. Fun Exercise - Feed the zombies 2", "11. Exercise - Traffic Simulation"],
        btn1: "Purchase full lesson plan",
        btn2: "Download a sample"
    },
    testimonial: {
        header: "Hear what our users say",
        papers: [
            // {
            //     // paper 1
            //     header:
            //         "",
            //     para:
            //         "“When students are allowed to be creative, such as making VR content they identify with, they absorb coding concepts along the way”",
            //     img: jason,
            //     alt: "Crofton House School",
            //     name: "Jason Madar",
            //     description: "Teacher"
            // },
            // paper 2
            {
                header:
                    "",
                para:
                    "I was first introduced to the C3D Java platform through my AP CS A class, as a way for us to familiarize ourselves with the Java language. Although I first thought that coding was intimidating, I quickly grew comfortable with coding in Java, thanks to the responsive and visual output that C3D offers. My favourite memory when using the C3D Java platform was when my class built a Zombie-eating game, as I felt challenged yet enthusiastic at the same time. C3D is definitely a powerful and creative tool that allows students to fully enjoy the coding experience.",
                img: chloe,
                alt: "student",
                name: "Chloe Lam",
                description: "Grade 11 student at Crofton House School"
            }
        ]
    },
    quote: {
        text: '“When students are allowed to be creative, such as making VR content they identify with, they absorb coding concepts along the way.  - Jason Madar”'
    }
};

export default data;
