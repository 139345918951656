import React from "react";
import data from "../../../data/landing.js"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { useMediaQuery } from "@material-ui/core";


const styles = makeStyles((theme, prop) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '3rem',
        marginBottom: '3rem'

    },
    text: {
        width: "40vw",
        textAlign: "center",
    },
    box: {
        flex: '1 0 auto',
        marginBottom: '3rem',
    },
    name: {
        paddingTop: "3vh",
        color: "white",
    },
    personimg: {

        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    company: {
        margin: "2vw",
        width: "25%",
        height: "auto"
    },
    circle: {
        margin: "0.5vw",
        height: "0.5vw",
        width: "0.5vw",
        "box-shadow": "0 0 0.5vw #FC5185",
        "background-color": "transparent",
        "border-radius": "50%",
        display: "inline-block",
        backgroundColor: "#FC5185",
    },
    arrows: {
        height: "5vh",
        width: "5vh",
        borderRadius: "3vw",
        border: "none",
        backgroundColor: "transparent",
        marginTop: "15vh",
        textAlign: "center",

    },
    button: {
        border: "0",
        outline: "none",
        fontSize: "3vw",
        backgroundColor: "transparent",
        borderRadius: "50%",
        height: "3vw",
        width: "3vw",
        display: "inline-block",
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: "500px",
        margin: "2rem",
        textAlign: "center"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },


}))
const CompaniesAndPeople = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const classes = styles()
    return (
        <div className={classes.root} style={{ flexDirection: "column" }}>
            <h2>{data.ppl.header}</h2>
            {/* companies */}
            <h3 style={{ color: "#11D3BC" }}>{data.company.subheader}</h3>
            <div style={{ maxWidth: "70vw", textAlign: "center" }}>
                {data.company.imgs.map((item, index) => (
                    <img key={index} className={classes.company} src={item} alt="" />
                ))}
            </div>

            {/*people*/}
            <div className={classes.root}>
                <h3 style={{ color: "#11D3BC", width: '80%' }}>{data.ppl.subheader}</h3>
                <div style={isSmallScreen ? { display: 'block' } : { display: 'flex' }}>
                    {data.ppl.lists.map((item, index) => (
                        <Card key={index} className={classes.card} style={{ flex: 1 }}>
                            <CardContent className={classes.box}>
                                <div style={{ margin: "1vw" }}>
                                    <div>{item.title}</div>

                                </div>
                            </CardContent>
                            <CardContent
                                className={classes.personimg}
                                style={(index === 0) ? { backgroundColor: "#FC5185" } : (index === 1) ? { backgroundColor: "#11D3BC" } : { backgroundColor: "#40514E" }}

                            >
                                <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }} >
                                    <Avatar align='center' alt="Remy Sharp" src={item.img} className={classes.large} />
                                    <div className={classes.name}>{item.name}</div>
                                </div>

                            </CardContent>
                        </Card>
                    ))}
                </div>
            </div>
        </div >
    )

}

export default CompaniesAndPeople