import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"



const useStyles = makeStyles((theme, props) => ({
    root: {
        width: '80%',

    },
    blockquote: {
        fontSize: '1.5rem',
        maxWidth: '700px',
        background: "#f9f9f9",
        borderLeft: "10px solid #11D3BC",
        margin: "1.5em auto",
        padding: "0.5em 10px",
        // quotes: `"\201C""\201D""\2018""\2019"`,

        "&:before": {
            color: "#11D3BC",
            content: "open-quote",
            fontSize: "4em",
            lineHeight: "0.1em",
            marginRight: "0.25em",
            verticalAlign: "-0.4em"
        }
        //   blockquote p {
        //     display: inline;
        //   }
    }
}));

const Video = props => {
    const classes = useStyles()
    return (
        <Grid className={classes.root}>
            <blockquote className={classes.blockquote}>
                {props.text}
            </blockquote>
        </Grid>
    )
}

export default Video