import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import Faq from "./cards/Faq"

const useStyles = makeStyles((theme) => ({
    root: {
        // marginTop: '2rem',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '1% 5%',
        },
    },
    txt: {
        display: 'inline-block',
        maxWidth: 500,
        margin: '2rem'
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: '2rem',
        justifyContent: 'center'
    }
}));
const FAQ = (props) => {
    const faqs = Object.values(props)
    const lastel = faqs.length - 1
    const classes = useStyles()
    return (
        <Grid item xs={12} className={classes.root}>
            <h2 align="center">FAQ</h2>
            <Grid item xs className={classes.planContainer}>
                {faqs.map((value, index) => {
                    let islast = false
                    let isfirst = false
                    if (index === lastel) {
                        islast = true
                    }
                    if (index === 0) {
                        isfirst = true
                    }
                    return (
                        <Faq {...value} key={index} islast={islast} isfirst={isfirst} />
                    )
                })}
            </Grid>
        </Grid>
    )

}

export default FAQ