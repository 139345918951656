import React, { useState } from 'react';
import axios from 'axios';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { TextField } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { useLocation } from 'react-router-dom'

const styles = theme => ({
    container: {
        maxWidth: "75rem",
        margin: "auto",
        textAlign: "center",
        justifyContent: "center",
        zIndex: 1000,
        merginBottom: '3rem'
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: "5px !important",
            width: "290px !important",
            maxWidth: "290px !important",
            maxHeight: "38px !important",
            marginRight: '0 !important'
        },
        borderRadius: "10px !important",
        minWidth: "168px !important",
        width: "auto !important"
    },
    successMessageContainer: {
        width: "100%"
    },
    error: {
        color: "#FC5185"
    },
    containerWidth: {
        width: '90%',
        textAlign: 'center',

        merginBottom: '3rem'
    }
})

const ContactForm = (props) => {
    const classes = props.classes;
    const location = useLocation()

    //State for Contact Form
    const [name,
        setName] = useState('');
    const [email,
        setEmail] = useState('');
    const [school,
        setSchool] = useState('');
    const [noofseats, setNoofseats] = useState('');
    const [message,
        setMessage] = useState('');
    const [errorMsg,
        setErrorMsg] = useState([]);

    //State for submit button
    const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

    //State for success submit
    const [isSent,
        setIsSent] = useState(false);
    const [contactName,
        setContactName] = useState('');

    //OnSubmit Handler
    const handleFormSubmit = (e) => {
        e.preventDefault();

        //Disable submit button avoid double click
        setDisabledSubmitBtn(true);

        //Valid user input
        let submitName = name.trim();
        let submitEmail = email.trim();
        let submitSchool = school.trim();
        let submitNoofseats = noofseats.trim();
        let submitMessage = message.trim();

        let submitValidation = true;
        let msg = [];
        if (!submitName && submitName.length < 1) {
            msg.push('Please enter valid name.');
            submitValidation = false;
        }
        if (!submitEmail && submitEmail.length < 1) {
            msg.push('Please enter valid email.');
            submitValidation = false;
        }
        if (!submitMessage && submitMessage.length < 1) {
            msg.push('Please enter message.');
            submitValidation = false;
        }
        //Valid form input, submit to backend
        if (submitValidation) {

            //Valid input, empty all error message
            setErrorMsg(['Sending Message......']);

            const contactInfo = {
                submitName,
                submitEmail,
                submitSchool,
                submitNoofseats,
                submitMessage
            }
            console.log(contactInfo)
            axios
                .post('https://c3d-mailer-server.netlify.app/.netlify/functions/server/c3d-contact', contactInfo)
                // .post('https://c3d-contact.herokuapp.com/c3d-contact', contactInfo)
                // .post('http://localhost:8080/c3d-contact', contactInfo)
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200 && response.data.isSent === true) {
                        setContactName(submitName);
                        //Empty all form input
                        setName('');
                        setEmail('');
                        setSchool('');
                        setNoofseats('');
                        setMessage('');

                        setErrorMsg([]);
                        //Hide the form and show success message
                        setIsSent(true);
                    } else {
                        setErrorMsg(["We couldn't get your messages, please wait for a moment."]);
                        setDisabledSubmitBtn(false);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMsg(["We couldn't get your messages, please wait for a moment."]);
                    setDisabledSubmitBtn(false);
                });

        }
        else {
            setErrorMsg(msg);
            setDisabledSubmitBtn(false);
        }

    }

    return (
        <div className="contact-form-container">
            <h2 align="center">Contact Form</h2>
            <Grid container classes={{ container: classes.containerWidth }} className={classes.container}>
                {!isSent
                    ? (
                        <form
                            onSubmit={handleFormSubmit}
                            action="/c3d-contact"
                            method="POST"
                            className="contact-form">
                            <TextField
                                label="Name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                name="name"
                                type="text"
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined" />
                            <TextField
                                label="Email"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                name="email"
                                type="email"
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined" />
                            <TextField
                                label="School"
                                onChange={e => setSchool(e.target.value)}
                                value={school}
                                name="school"
                                type="text"
                                fullWidth
                                margin="normal"
                                variant="outlined" />
                            {
                                location.pathname === '/java' ? '' :

                                    <TextField
                                        label="No of Seats"
                                        onChange={e => setNoofseats(e.target.value)}
                                        value={noofseats}
                                        name="noofseats"
                                        type="number"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined" />
                            }
                            <TextField
                                label="Message"
                                onChange={e => setMessage(e.target.value)}
                                name="message"
                                value={message}
                                multiline={true}
                                rows="6"
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined" />
                            <div className="response-message-container">{errorMsg.map((msg, index) => {
                                return <Typography key={index} variant="body2" color="secondary" >{msg}</Typography>
                            })}</div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                type="submit"
                                disabled={disabledSubmitBtn}>
                                Send
                            </Button>
                        </form>
                    )
                    : (
                        <Grid container className={classes.container}>
                            <Grid className={classes.successMessageContainer}>
                                <Typography variant="h5" color="primary">Thank you for your interest, {contactName}</Typography>
                                <Typography variant="body2">We’ll get back to you shortly.</Typography>
                            </Grid>
                        </Grid>
                    )}
            </Grid>
        </div>

    );
}

export default withStyles(styles)(ContactForm);