const data = {
    landing: {
       title:'virtual reality education for students of all ages | C3D.io ',
       meta:'C3D.io provides an innovative teaching tool that children can learn to code. Bring virtual reality education to your home and classes.' 
    },
    teacher:{
      title:'Teach kids to code by bringing VR into the Classroom | C3D',
      meta:' C3D makes teaching kids to code even easier! Introduce your students to coding concepts and virtual reality through fun and interactive coding lessons.'
    },
    parents:{
      title:'Coding classes for kids - help them find passion in coding| C3D.io',
      meta:'For future-thinking parents who help their kids find passion in coding | C3D Teach and Learn to code VR'
    },
    java:{
      title:'Use C3D Java to teach AP Computer Science Curriculum',
      meta:'C3D Java helps teachers deliver the AP Computer Science curriculum in an engaging way. The Java tool turns a Java lesson into a fun VR coding session.'
    },
    about:{
      title:'How VR can help Education',
      meta:'Meet C3D co-founders and team. Find how VR transforms education and coding curriculum.'
    },
    pricing:{
      title:'C3D VR Coding License Pricing',
      meta:'C3D license is very reasonably priced and is available for schools, teachers, parents and individual users.'
    }
   
}

export default data