import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import Testimonial from "../cards/Testimonial"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '5%',
        display: 'block',
        marginTop: '5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: '2rem',
        justifyContent: 'center'
    }
}));

const TeacherTestimonial = props => {
    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.root}>
            <h2 align="center">{props.header}</h2>
            <Grid item xs={12} className={classes.planContainer}>
                {props.papers.map((value, index) => {
                    return (
                        <Testimonial {...value} key={index} />
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default TeacherTestimonial