import React, { useEffect } from "react";

import LandingPage from "../pages/LandingPage";
import About from "../pages/About";
import Parents from "../pages/Parents";
import Articles from "../pages/Articles";
import Teachers from "../pages/Teachers";
import Java from "../pages/Java";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CodingLesson from "../pages/CodingLesson";
import Solution from "../pages/Solution";
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import data from '../../data/head';
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ACaseStudy from "../pages/ACaseStudy";
import Pricing from "../pages/Pricing";

import ReactGA from 'react-ga';

ReactGA.initialize('UA-102209441-1');


//user ROUTE to switch from different pages
const Body = () => {
  const location = useLocation();
  console.log(location.pathname);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const getTitle = (location, data)=>{
    let t='';
    switch(location.pathname) {
      case '/':
        t= data.landing.title;
        break;
      case '/school':
          t= data.teacher.title;
          break;
      case '/parents':
        t=data.parents.title;
        break;
      case '/java':
          t=data.java.title;
          break; 
      case '/about':
          t=data.about.title;
          break;
      case '/pricing':
          t=data.pricing.title;
          break;
      default:
        t= 'virtual reality education for students of all ages';
    }
    return t;
  }

  const getMeta = (location, data)=>{
    let m='';
    switch(location.pathname) {
      case '/':
        m= data.landing.meta;
        break;
      case '/school':
          m= data.teacher.meta;
          break;
      case '/parents':
        m=data.parents.meta;
        break;
      case '/java':
          m=data.java.meta;
          break; 
      case '/about':
          m=data.about.meta;
          break;
      case '/pricing':
          m=data.pricing.meta;
          break;
      default:
        m= 'Children can learn coding concepts while creating their own VR world and with one click, explore their creations with a mobile phone and a VR cardboard';
    }
    return m;
  }

  return (
    <React.Fragment>
       <Helmet>
            <title>{getTitle(location, data)}</title>
            <meta name="google-site-verification" content="cRQH_11UFBFhicuU6GJBMRscV5r4lYa7aySyPsYqCm8" />
            {/* <meta name="googlebot" content="noindex" />
            <meta name="googlebot-news" content="nosnippet" /> */}
            {/* <meta name="og:title" content="virtual reality education for students of all ages" /> */}
            <meta name="description" content={getMeta(location, data)} />
      </Helmet>
    <Switch>
      <Route path="/" exact component={LandingPage} />
      <Route path="/parents" exact component={Parents} />
      <Route path="/school" exact component={Teachers} />
      <Route path="/articles" exact component={Articles} />
      <Route path={"/articles/:title"} component={ACaseStudy} />
      <Route path="/about" component={About} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/java" component={Java} />
      <Route path="/codeca-privacy-policy" component={PrivacyPolicy} />
      <Route path="/hour-of-code-lesson-plan" component={CodingLesson} />
      <Route path="/solution" component={Solution} />
    </Switch>
    </React.Fragment>
  );
}

export default withRouter(Body);
