import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import CardContent from '@material-ui/core/CardContent'
import GeneralBtn from "../elems/GeneralBtn"
import OneTimePayment from "../OneTimePayment";

const useStyles = makeStyles((theme, props) => ({
    root: {
        padding: '5%',
        textAlign: 'center',
        height: 'auto',


    },
    title: {
        // marginBottom: '2%'
    },
    content: {
        // paddingLeft: '1%',
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: "20%",
        // },
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: "30%",
        // },
    },
    btn: {
        marginTop: "2%",

    },
    stylebtn: {
        color: '#11D3BC',
        backgroundColor: 'white',
        border: '1px solid #11D3BC',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '2rem'
        },
        "&:hover": {
            backgroundColor: 'white',
        }
    },
    ccontainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '2rem',
        justifyContent: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },
    img: {
        maxWidth: 500,
    },
    image: {
        maxWidth: '100%',
        height: 'auto'
    },
    txt: {
        display: 'inline-block',
        maxWidth: 500,
        // paddingLeft: '1%',
        margin: '10rem 4rem',
        [theme.breakpoints.down('896')]: {
            margin: '2rem 4rem',
            //width: '60%',
            textAlign: 'left'
        }
    }
}));

const JavaContent = props => {
    const classes = useStyles();
    const handledownlod = () => {
        window.open('https://drive.google.com/file/d/10DkSjV3725-CzIZf5_aJRCuYxr7h-qOs/view');
    }

    return (
        <Grid className={classes.root} >
            <CardContent>
                <h2 className={classes.title}>
                    {props.header}
                </h2>
            </CardContent>
            <Grid sx={12} className={classes.content}>
                <Grid container className={classes.ccontainer}>
                    <div xs={6} className={classes.img}>
                        <img src={props.imgUrl} className={classes.image} alt="Screenshot of the java coding sample" />
                    </div>
                    <div xs={6} className={classes.txt}>
                        {props.items.map((item, index) => {
                            return (<Grid key={index} item xs={12} className={classes.item}>
                                <p align="left">
                                    {item}
                                </p>
                            </Grid>)
                        })
                        }
                    </div>
                </Grid>

            </Grid>
            <Grid item xs={12} className={classes.btn}>
                {/* <GeneralBtn label={props.btn1} /> */}
                <OneTimePayment />
                <GeneralBtn label={props.btn2} stylebtn={classes.stylebtn} link={() => handledownlod()} />
            </Grid>
        </Grid>
    )
}

export default JavaContent