import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import PersonCard from '../cards/Person'
import Box from '@material-ui/core/Box';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        textAlign: "center",
        padding: "1vh 5vw",
    },
    desc: {
        textAlign: "left",
        margin: "5vh 0",
        paddingLeft: '5vw',
        [theme.breakpoints.down('960')]: {
            paddingLeft: '3vw',
        },
    },
    box: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridAutoRows: '1fr',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            // gridTemplateColumns: '1fr 1fr',
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
        flexWrap: 'wrap',
        backgroundColor: 'white',


    }
}));

const Team = props => {
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    return <div className={classes.root}>
        <ThemeProvider theme={theme}>
            <Grid item xs={12} className={classes.container}>
                <h3 align="left" xs={12} className={classes.desc}>Ambassador</h3>
            </Grid>

            <Box className={classes.box}>

                {props.lists.map((item, index) => (
                    <PersonCard key={index} {...item} />

                ))}
            </Box>

        </ThemeProvider>
    </div>
}

export default Team