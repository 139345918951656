import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const styles = (theme, prop) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        "flex-direction": "column",
        backgroundColor: "#EDEEEA",

    },
    img: {
        maxWidth: "100%",
        height: "auto",
    },
    txt: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem'
        }
    }
})


class ThreeSteps extends Component {
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                {/* c3d in 3 steps */}
                <h2 style={{ paddingTop: "3vw" }}>{data.ThreeSteps.title}</h2>
                <Grid item container style={{ padding: "3vw 0", textAlign: "center", justifyContent: "center" }}>
                    {data.ThreeSteps.box.map((item, index) => (
                        <Grid xs={12} md={3} key={index} item container direction="row" style={{ margin: '1%', position: "relative", textAlign: "center", display: "flex", justifyContent: "space-evenly", flexDirection: "column", }}>
                            <div style={{ padding: "0 3vw" }}><img className={classes.img} src={item.img} alt="steps" /></div>
                            <h3 className={classes.txt} style={{ fontWeight: "600", padding: "0 5vw", maxWidth: "1/3" }}>{item.caption}</h3>
                        </Grid>
                    ))}
                </Grid>
                <p style={{ maxWidth: "100%", paddingBottom: "3.5vw" }}>{data.ThreeSteps.description}</p>
            </div>
        )
    }
}

export default withStyles(styles)(ThreeSteps)