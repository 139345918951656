import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from "@material-ui/core"
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { isWebpSupported } from '../../utils/isWebpSupport';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        height: "100%",
        width: "100%",
        marginBottom: "3rem",
        [theme.breakpoints.up('md')]: {
            // height: '500px',
        }
    },
    container: {
        textAlign: 'center',
        margin: '0 auto'
    },
    header: {
        position: 'relative',
        color: "white",
        textAlign: "center",
        padding: '15vw 18vw'

    },
    desc: {
        position: 'relative',
        maxWidth: '450px',
        margin: "3rem 0",
        textAlign: "center",

    },
    btn: {
        position: 'relative',
        backgroundColor: "#11D3BC"
    },

}));

const HeroCenterText = props => {
    const classes = useStyles();
    console.log(props.img)

    return (

        <Grid item xs={12} className={classes.root} style={{
            background: `linear-gradient(
            rgba(0, 0, 0,.5), 
            rgba(0, 0, 0,.5)
        ),url(${isWebpSupported() ? props.imgWebp : props.img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }}>
            <Grid item xs={12} className={classes.container}>
                <ThemeProvider theme={theme}>
                    <h1 className={classes.header}>{props.header}</h1>
                    {/* <Typography className={classes.desc} variant="body1">{props.description}</Typography> */}
                </ThemeProvider>

            </Grid>
        </Grid>

    )
}

export default HeroCenterText