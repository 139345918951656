import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Image, canUseWebP } from "react-img-webp";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        position: "relative",
        textAlign: "center",
        padding: "1vh 3vw",
        [theme.breakpoints.down('sm')]: {
            padding: "1vh 4vw",
        },
    },
    imgbg: {
        width: '100%',
        height: "auto",
    },
    container: {
        textAlign: 'center',
        margin: 0,
    },
    title: {
        fontWeight: 'normal',
        fontSize: '2rem',
        position: 'relative',
        maxWidth: '500px',
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100% !important'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        }

    },
    header: {
        position: 'relative',
        margin: "3rem 0",
        maxWidth: '600px',
        textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100% !important'
        }
    },
    desc: {
        textAlign: "left",
        margin: "5vh 0"
    },
    flexcontainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'inline !important'
        }
    },
    adjustwidth: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100% !important'
        }
    },


    secondroot: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '3rem',
        justifyContent: 'center',
        // alignContent: 'center',
        alignSelf: 'center',
    },
    secondimg: {
        maxWidth: 500,
        paddingLeft: '1rem',
        [theme.breakpoints.up('1009')]: {
            marginTop: '2rem',
        },
    },
    imgbottomBorder: {
        maxWidth: '100%',
        height: 'auto',
        padding: '0.5rem',
        borderBottom: '30px solid #11D3BC'

    },
    imgtopBorder: {
        maxWidth: '100%',
        height: 'auto',
        padding: '0.5rem',
        borderTop: '30px solid #FC5185'
    },
    secondtxt: {
        display: 'inline-block',
        maxWidth: 500,
        // margin: '1rem 0.5rem',
        textAlign: 'left',
        borderLeft: '2px solid #40504e',
        paddingLeft: '3rem',
        [theme.breakpoints.down('1147')]: {
            // margin: '1rem 1rem 2.2rem',
            // maxWidth: 700,
        }
    },
    bordergrid: {
        borderTop: '2px solid #40504e', marginTop: '25px', marginRight: '20px',
        // [theme.breakpoints.down('md')]: {
        //     display: 'none'
        // },

    },

    gridtxtContainer: {
        justifyContent: 'center',
        padding: '5rem',
        [theme.breakpoints.down('960')]: {
            padding: '1rem',
        }
    },

    gridstyle: {
        // maxWidth: 500,
        paddingLeft: '1rem',
        textAlign: 'left',
        [theme.breakpoints.down('1147')]: {
            // marginTop: '1.5rem !important',
        }
    },
    secondgridstyle: {
        // maxWidth: 500,
        paddingLeft: '1rem',
        textAlign: 'left',
        [theme.breakpoints.down('960')]: {
            marginTop: '-2rem !important',
        }
    },

    secondleftroot: {
        // display: 'flex',
        // flexWrap: 'wrap',
        marginTop: '2rem',
        justifyContent: 'center',
        // alignContent: 'center',
        // alignSelf: 'center',
    },
    secondleftimg: {
        maxWidth: 500,
        margin: '2rem 1rem',
    },
    secondleftimage: {
        width: '100%',
        height: 'auto'
    },
    secondlefttxt: {
        display: 'inline-block',
        maxWidth: 500,
        margin: '5rem 2rem',
        borderLeft: '2px solid #40504e',
        paddingLeft: '3rem',
        textAlign: 'left',
        [theme.breakpoints.down('960')]: {
            margin: '1rem 1rem 2.2rem',
        }
    }
}));

const Abouthero = props => {
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    return <div className={classes.root}>
        <Grid item xs={12} className={classes.container}>
            {/* <ThemeProvider theme={theme}> */}
            <Grid container xs={12} style={{ padding: '2rem' }}>
                <Grid item xs={12} md={6}>
                    <h1 className={classes.title} dangerouslySetInnerHTML={{ __html: props.title }}></h1>
                </Grid>
                <Grid item xs={12} md={6}>
                    <p className={classes.header}>{props.header}</p>
                </Grid>
                <Image src={props.img} webP={props.imgWebp} className={classes.imgbg} alt="students sitting with teacher in class" />
            </Grid>

            <Grid container className={classes.secondroot}>
                <div xs={6} className={classes.secondtxt}>
                    <h3 style={{
                        fontWeight: 'normal',
                        fontSize: '2rem',
                    }} dangerouslySetInnerHTML={{ __html: props.secondTitle }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                </div>
                <div xs={6} className={classes.secondimg}>
                    <img src={props.jasonImg} className={classes.imgbottomBorder} alt="Jason the CTO of C3D" />
                    <img src={props.tomokoImg} className={classes.imgtopBorder} alt="Tomoko the CEO of C3D" />
                </div>

            </Grid>

            <Grid container className={classes.gridtxtContainer}>
                {/* <Grid xs={12} md={3} className={classes.bordergrid}></Grid> */}
                <Grid xs={12} md={6} align="left" className={classes.gridstyle}>
                    <p dangerouslySetInnerHTML={{ __html: props.description1 }}></p>
                </Grid>
                <Grid xs={12} md={6} align="left" className={classes.secondgridstyle}>
                    <p dangerouslySetInnerHTML={{ __html: props.description2 }}></p>
                </Grid>
            </Grid>


            <Grid container className={classes.secondleftroot}>
                <div xs={6} className={classes.secondleftimg}>
                    <Image src={props.tedtalkImg} webP={props.tedtalkImgWebp} className={classes.secondleftimage} alt="Ted talk talking about VR" />
                </div>
                <div xs={6} className={classes.secondlefttxt}>
                    <h3 style={{
                        fontWeight: 'normal',
                        fontSize: '1rem',
                    }} dangerouslySetInnerHTML={{ __html: props.quoteauthor }} ></h3>
                    <p dangerouslySetInnerHTML={{ __html: props.quote }} ></p>
                </div>
            </Grid>
        </Grid>
    </div>
}

export default Abouthero