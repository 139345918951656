import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import GeneralBtn from "./elems/GeneralBtn";

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     body: {
//         fontSize: 14,
//     },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);


const useStyles = makeStyles((theme, props) => ({
    root: {
        textAlign: 'center',
        // margin: '5%',
        padding: '1%',
        // paddingLeft: '5%',
        height: 'auto',
        width: "100%"
    },
    title: {
        marginBottom: '2%'
    },
    content: {
        padding: '8%',
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)"

    },
    btn: {
        padding: '5%',
        // marginTop: '10%',
        textAlign: "center",
        // width: "90%"
    },
    link: {
        color: '#FC5185'
    },
    stylebtn: {
        color: '#11D3BC',
        backgroundColor: 'white',
        border: '1px solid #11D3BC'
    },
    form: {
        textAlign: "center",
        justifyContent: "center"
    },
    txt: {
        width: "100%",
        textAlign: "center",
        maxWidth: "400px"
    },
    btnClass: {
        borderRadius: "5px !important",
        color: 'white !important',
        marginRight: "0 !important",
        marginTop: "1rem !important",
        backgroundColor: '#FC5185 !important',
        // maxWidth: "400px",
        "&:hover": {
            backgroundColor: "#bf4d6f !important",
        },
        [theme.breakpoints.down('xs')]: {
            borderRadius: "5px !important",
            width: "250px !important",
            maxWidth: "290px !important",
            maxHeight: "38px !important"
        },
        // borderRadius: "10px",
        minWidth: "168px !important",
        width: "auto"
    },
    btnroot: {
        [theme.breakpoints.down('xs')]: {
            padding: "0.5vw 4vw !important",
        },
        padding: "0.5vw 2vw !important",
        boxSizing: 'border-box !important',
        fontWeight: '900 !important',
        marginTop: "3% !important",
        marginRight: "0 !important"
    }

}));




const Python = props => {
    const MY_FORM_ID = 1761745
    const [status, setStatus] = useState(null);
    const YOUR_FORM_URL = `https://app.convertkit.com/forms/${MY_FORM_ID}/subscriptions`

    const classes = useStyles();

    const handleSubmit = async (e) => {
        console.log(e.target)
        e.preventDefault();
        const data = new FormData(e.target);

        try {
            const response = await fetch(
                YOUR_FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );

            const json = await response.json();

            if (json.status === 'success') {
                console.log('subscription:', status)
                setStatus('SUCCESS');
                return;
            }
            setStatus('ERROR');
        } catch (err) {
            setStatus('ERROR');
        }
    };

    return (
        // <Grid className={classes.root} >
        <Grid className={classes.btn}>
            <h3 className={classes.title}>
                Python Version Coming Soon
                </h3>
            <p>
                Sign up to join the beta trial
                </p>
            {status === null &&
                <form
                    className={classes.form}
                    noValidate autoComplete="off"
                    action={YOUR_FORM_URL}
                    method="post"
                    onSubmit={handleSubmit}
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                >

                    <TextField label="Name"
                        name="fields[first_name]" className={classes.txt} id="outlined-basic" label="Your Name" variant="outlined" size="small" />
                    <br />
                    <TextField name="email_address"
                        aria-label="Your email"
                        type="email"
                        className={classes.txt} id="outlined-basic" label="Email Address" variant="outlined" size="small" />
                    <br />
                    {/* <GeneralBtn type="submit" stylebtn={classes.btnClass} label="Sign Up" /> */}
                    <Button
                        classes={{ root: classes.btnroot }}
                        type="submit"
                        className={classes.btnClass}
                    >
                        Subscribe
      </Button>
                </form>}
            {status === 'SUCCESS' && <p>Thank you for signing up!</p>}
            {status === 'ERROR' && <p>Oops, try again.</p>}
        </Grid>
        // </Grid>
    )
}

export default Python