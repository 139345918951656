import React from 'react'
import data from '../../data/privacypolicy'

const styles = theme => ({
    header: {
        margin: '5%',
    },


})

const PrivacyPolicy = () => {
    const classes = styles()
    return (
        <div style={{ textAlign: "center", margin: "auto", width: '80%' }}>
            <h1 className={classes.header}>{data.head.title}</h1>
            <p align="right" style={{ margin: '5%' }}>{data.head.updatedDate}</p>
            <h2>{data.head.header1}</h2>
            <h2>{data.head.header2}</h2>
            <p style={{ textAlign: 'left' }}><div dangerouslySetInnerHTML={{ __html: data.head.description }}></div></p>
            <h2 style={{ textAlign: 'left', marginTop: '10%' }}>{data.contentList.title}</h2>
            {data.contentList.detail.map((item) => {
                return <p style={{ textAlign: 'left' }}>{item.title}</p>
            })}
            {
                data.contentList.detail.map((item) => {
                    return (
                        <div>
                            <h3 style={{ textAlign: 'left', marginTop: '10%' }}>{item.title}</h3>
                            <p style={{ textAlign: 'left' }}>
                                <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            </p>
                        </div>
                    )
                })
            }
            <div style={{ textAlign: 'left', marginTop: '10%' }} dangerouslySetInnerHTML={{ __html: data.table }}></div>
        </div>
    )
}

export default PrivacyPolicy