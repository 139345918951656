import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

const styles = (theme, prop) => ({
    root: {
        display: "flex",
        "flex-direction": "column",
    },
    title: {
        textAlign: "left",
        margin: "1% 9vw 4%",

    },
    grid: {
        // minWidth: '300px !important',
        // maxWidth: 380,
        width: '90%',
        textAlign: 'center',
    },
    list: {
        [theme.breakpoints.down('md')]: {
            display: "flex",
            margin: "0",
        },
        flexDirection: "column",
        margin: "3vw 6vw",

    }
})

class ProjectGallery extends Component {
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                {/* project gallery */}
                <h2 style={{ textTransform: "capitalize", marginTop: "13vw", }} className={classes.title}>{data.ProjectGallery.title}</h2>
                <p className={classes.title}>{data.ProjectGallery.description}</p>
                {/* <div style={{ width: '90%' }}>
                    <ol className={classes.list}>
                        {data.ProjectGallery.bullets.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ol>
                </div> */}

                <Grid container direction="row" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    {data.ProjectGallery.gallery.map((item, index) => (
                        <Grid key={index} item xs={12} md={4} className={classes.grid}>
                            <img style={{ width: '300px' }} src={item.img} alt="gallery" />
                            <p style={{ fontWeight: "600" }}>{item.title}</p>
                        </Grid>
                    ))}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(ProjectGallery)