import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        textAlign: "left !important",
        padding: "1vh 2vw",
    },
    container: {
        padding: "3vh 0 !important",
    },
    desc: {
        textAlign: "left !important",
        paddingLeft: '3vw'
    },
    box: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        backgroundColor: 'rgb(255, 255, 255)',
        padding: '8px',
    }
}));

const Wwdo = props => {
    // const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    return <div className={classes.root}>
        <ThemeProvider theme={theme}>
            <Box className={classes.box}>
                <Grid item xs={12} md={3} sm={12} align="flex-start">
                    <h2 className={classes.desc}>{props.title}</h2>
                </Grid>

                {props.plans.map((item, index) => (
                    <Grid key={index} item xs={12} md={4} sm={6} className={classes.desc}>
                        <h3 align="left">{item.title}</h3>
                        <ul>
                            {item.items.map((e, index) => (
                                <li key={index} align="left"><span>{e.desc}</span></li>
                            ))}
                        </ul>
                    </Grid>
                ))}
            </Box>
        </ThemeProvider>
    </div>
}

export default Wwdo