import React from 'react'
import data from "../../data/about";
import Abouthero from '../parts/about/Abouthero'
import Wwdo from '../parts/about/Wwdo'
import Team from '../parts/about/Team'
import Ambassadors from '../parts/about/Ambassadors'



const About = props => {
  return (<div style={{ textAlign: 'center' }}>
    <Abouthero {...data.hero} />
    <Wwdo {...data.whatwedo} />
    <Team {...data.team} />
    <Ambassadors {...data.ambassadors} />
  </div>)
}

export default About