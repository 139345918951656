import React from "react";
import data from "../../../data/landing.js";
import { makeStyles } from '@material-ui/core/styles';


const styles = makeStyles((theme, prop) => ({
    root: {
        marginTop: '4rem !important',
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.up('md')]: {
            margin: "1rem"
        }
    },
    text: {
        width: "40vw",
        textAlign: "center",
    },
    title: {
        width: "30vw",
        textAlign: "center",
    },
    grid: {
        display: "flex",
        "flex-direction": "column",
        alignItems: "left",
        padding: "5vw",
        [theme.breakpoints.up('md')]: {
            alignItems: "center",
        }
    },
    lists: {
        maxWidth: '90%',
        order: 2,
        [theme.breakpoints.up('md')]: {
            maxWidth: "30vw",
            // paddingRight: "5vw",
            maxWidth: "75vw",
        },
        [theme.breakpoints.up('1333')]: {
            order: 1,
        }
    },
    bullet: {
        color: "#11D3BC",
    },
    list: {
        color: "#000000",
        margin: 0,
        fontWeight: 'regular !important'
    },
    img: {
        padding: '5vw',
        order: 1,
        [theme.breakpoints.up('1333')]: {
            order: 2
        }
    }
}))
const Innovative = () => {

    const classes = styles()
    return (
        <div className={classes.root}>
            {/* innovative */}
            <div className={classes.lists}>
                <h2 style={{ color: "#11D3BC" }}>{data.innovative.subheader}</h2>
                <ul className={classes.bullet}>
                    {data.innovative.lists.map((item, index) => (
                        <li key={index} style={{ paddingBottom: "2vw" }}><h3 className={classes.list}>{item}</h3></li>
                    ))}
                </ul>
            </div>
            <div className={classes.img}>
                <img style={{ width: "100%", height: "auto" }} src={data.innovative.img} alt="innovative" />
            </div>
        </div>
    )

}

export default Innovative