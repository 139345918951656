import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import GeneralBtn from "./elems/GeneralBtn";
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     body: {
//         fontSize: 14,
//     },
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover,
//         },
//     },
// }))(TableRow);


const useStyles = makeStyles((theme, props) => ({
    root: {
        textAlign: 'center',
        margin: '5%',
        padding: '1%',
        height: 'auto',
        width: "100%",
        alignItems: "center",

    },
    title: {
        marginBottom: '2%'
    },
    btnClass: {
        [theme.breakpoints.up('sm')]: {
            // textAlign: "left",
        },
    }

}));

const Getstart = props => {
    const classes = useStyles();

    return (
        <Grid container item xs={12} className={classes.root} >
            <Grid item xs={12} md={6}>
                <h2 className={classes.title}>
                    {props.title}
                </h2>
                <p>
                    {props.subtitle}
                </p>
            </Grid>
            <Grid item xs={12} md={6} className={classes.btnClass}>
                <GeneralBtn label={props.btnLabel} link={props.signupLink} />
            </Grid>
        </Grid>
    )
}

export default Getstart