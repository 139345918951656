import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import { CardContent } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import GeneralBtn from "../elems/GeneralBtn";


const styles = (theme, prop) => ({
    root: {
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",

    },
    title: {
        textAlign: "center",
        color: "#FC5185",
        fontWeight: "bold",
        padding: "3vw 0",
        margin: 0,
        [theme.breakpoints.down('md')]: {
            backgroundColor: "#E5E5E5"
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: "#FFFFFF",
        },


    },
    sbtn: {
        color: "#FFFFFF",
        backgroundColor: "#FC5185",
        justifyContent: "center",
        "&:hover": {
            backgroundColor: "#bf4d6f",
        }
    },
    company: {
        "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.25)",
        borderRadius: "0.7vw",
        margin: "0 5vw",
        display: "flex",
        "flex-direction": "column",
        minWidth: 300,
        textAlign: 'center'
    },
    recommend: {
        backgroundColor: "#333333",
        borderRadius: "0.7vw 0.7vw 0 0",
        color: "#FFFFFF",
        minWidth: 300,
        margin: "0 5vw",
        [theme.breakpoints.down('sm')]: {
            marginTop: "5vh"
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "0"
        }
    },
    center: {
        display: "flex",
        justifyContent: "center",
        marginTop: '30px'
    },

})

class MonthlySubscription extends Component {
    handleSignUpClick() {
        window.open('https://canada.c3d.io/signup.html')
    }
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                {/* monthly subscription */}
                <Grid container style={{ backgroundColor: "#E5E5E5", paddingBottom: "3vw" }} direction="column">
                    <Grid item xs={12}><h2 className={classes.title}>{data.MonthlySub.title}</h2></Grid>
                    <Grid item container xs={12} direction="row" style={{ padding: "2vw 0vw" }}>
                        <Grid item sm={2} />
                        <Grid item sm style={{ width: '100%' }}>
                            <p style={{ textAlign: "center", fontWeight: "600" }}>
                                {data.MonthlySub.subtitle}
                            </p>
                            <div className={classes.center}>
                                <ul style={{ display: "inline-block", textAlign: "left" }}>
                                    {data.MonthlySub.learn.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <Grid container direction="row" className={classes.center}>
                                {data.MonthlySub.programs.map((item, index) => (
                                    <Grid key={index} item xs={12} md={6} style={{ textAlign: "center" }}>
                                        <h3 className={classes.recommend} style={{ visibility: index === 1 ? "visible" : "hidden" }}>Recommended</h3>
                                        <Card className={classes.company} style={{ borderRadius: index === 1 ? "0 0 0.7vw 0.7vw" : "0.7vw" }}>
                                            <CardContent>
                                                <img src={item.img} alt="program" />
                                                <h3>{item.time}</h3>
                                            </CardContent>
                                            <CardActions style={{ textAlign: 'center', justifyContent: 'center' }}>
                                                <GeneralBtn stylebtn={classes.sbtn} label={item.button} link={() => this.handleSignUpClick()} />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item sm={2} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(MonthlySubscription)