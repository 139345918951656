import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, CardActions } from '@material-ui/core';
import GeneralBtn from "../elems/GeneralBtn";
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from "@material-ui/core"
import { Image, canUseWebP } from "react-img-webp";

const styles = makeStyles((theme, prop) => ({
    root: {
        margin: '5%',
        display: "flex",
        "flex-direction": "column",
        textAlign: "center",
        marginTop: '3rem',

    },
    button: {
        backgroundColor: "#FC5185",
        "&:hover": {
            backgroundColor: "#bf4d6f",
        }
    },
    img: {
        width: "80%",
        heigth: 'auto'
    },
    btnPosition: {
        justifyContent: "center",
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            bottom: '1.5rem',
            right: '1.5rem',
        },
    }
}))

const OnlineClassOffer = (props) => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("1025"))
    const handleClick = () => {
        window.open('https://wp.c3d.io/product-category/online-coding-class/');
    }
    const classes = styles()
    return (
        <div className={classes.root}>
            {/* online class offer */}
            <Grid container>
                {isSmallScreen ? '' :
                    <Grid item xs={1} />}
                <Grid container item xs direction="column">
                    <h2 style={{ fontWeight: "bold" }}>{data.OnlineClass.title}</h2>
                    <p style={{ paddingTop: "2vh" }}>{data.OnlineClass.description}</p>

                    <Grid container style={{ display: "flex", padding: "2vw 0" }}>
                        {data.OnlineClass.courses.map((item, index) => (
                            <Grid key={index} item xs={12} md style={{ display: "flex", width: "100%", }}>
                                <Card style={{ padding: "2vw", margin: "20px", position: "relative", flex: "1", minWidth: 200, minHeight: 250 }}>
                                    <CardContent style={{ padding: "1vw 0" }}>
                                        <h3>{item.title}</h3>
                                        <Image src={item.imgUrl} webP={item.imgUrlWebp} className={classes.img} alt="online classes envirenment" />
                                        {/* <img className={classes.img} src={item.imgUrl} alt="online classes envirenment" /> */}
                                        <p style={{ padding: "1vw 0" }}>{item.description}</p>

                                    </CardContent>
                                    <CardActions style={{ marginTop: '25px', justifyContent: "center", }}>
                                        <div className={classes.btnPosition}>
                                            <GeneralBtn stylebtn={classes.button} label={item.button} link={() => index === 0 ? handleClick() : ''} disableBtn={index === 1 ? true : false} />
                                        </div>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {isSmallScreen ? '' :
                    <Grid item xs={1} />}
            </Grid>

        </div>
    )

}

export default OnlineClassOffer