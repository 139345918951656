import React, { useRef, useState, useEffect } from 'react'
import data from "../../data/pricing";
import Pricinghero from '../parts/pricing/Pricinghero'
import SchoolPlan from '../parts/pricing/SchoolPlan'
import JavaPlan from '../parts/pricing/JavaPlan';
import IndividualPlan from '../parts/pricing/IndividualPlan';
import { useMediaQuery } from "@material-ui/core";
const scrollToRefObject = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })



const Pricing = props => {
    const [selectedPlan, setSelectedPlan] = useState(0)
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const schoolPlan = useRef(SchoolPlan)
    const javaPlan = useRef(JavaPlan)
    const indvPlan = useRef(IndividualPlan)

    const handler = (e, index) => {
        setSelectedPlan(parseInt(index))
    }

    const schoolPlanScroll = () => scrollToRefObject(schoolPlan)
    const javaPlanScroll = () => scrollToRefObject(javaPlan)
    const indvPlanScroll = () => scrollToRefObject(indvPlan)

    const smallScreenHandler = (e, index) => {
        if (index === 0) {
            schoolPlanScroll()
        } else if (index === 1) {
            javaPlanScroll()
        } else {
            indvPlanScroll()
        }
    }



    return (<div style={{ textAlign: 'center' }}>

        {
            isSmallScreen ?
                <React.Fragment>
                    <Pricinghero {...data.hero} handler={smallScreenHandler} selected={selectedPlan} />
                    <div style={{ textAlign: 'center' }}>
                        <div ref={schoolPlan} style={{ width: '100%' }}>
                            <SchoolPlan {...data.school} />
                        </div>
                        <div ref={javaPlan} style={{ width: '100%' }}>
                            <JavaPlan {...data.java} />
                        </div>
                        <div ref={indvPlan} style={{ width: '100%' }}>
                            <IndividualPlan {...data.individual} />
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <Pricinghero {...data.hero} handler={handler} selected={selectedPlan} />
                    <div style={{ textAlign: 'center', margin: '4rem' }}>

                        {selectedPlan === 0 ?
                            <SchoolPlan {...data.school} />
                            :
                            selectedPlan === 1 ?
                                <JavaPlan {...data.java} /> :
                                <IndividualPlan {...data.individual} />
                        }
                    </div>
                </React.Fragment>
        }

    </div>)
}

export default Pricing