import React, { useEffect, useReducer } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import GeneralBtn from "../parts/elems/GeneralBtn"
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme, props) => ({
    alink: {
        textDecoration: "none",
        color: '#FC5185',
        cursor: 'pointer',
    }
}))

const fetchCheckoutSession = async () => {
    // return fetch('/create-checkout-session', {
    const data = await fetch('https://c3d-payment.netlify.app/.netlify/functions/server/create-checkout-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((res) => res.json())
    return data
};

const formatPrice = ({ amount, currency, quantity }) => {
    const numberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
    });
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
        if (part.type === 'decimal') {
            zeroDecimalCurrency = false;
        }
    }
    amount = zeroDecimalCurrency ? amount : amount / 100;
    const total = (quantity * amount).toFixed(2);
    return numberFormat.format(total);
};

function reducer(state, action) {
    switch (action.type) {
        case 'useEffectUpdate':
            return {
                ...state,
                ...action.payload,
                price: formatPrice({
                    amount: action.payload.unitAmount,
                    currency: action.payload.currency,
                    quantity: state.quantity,
                }),
            };
        case 'increment':
            return {
                ...state,
                quantity: state.quantity + 1,
                price: formatPrice({
                    amount: state.unitAmount,
                    currency: state.currency,
                    quantity: state.quantity + 1,
                }),
            };
        case 'decrement':
            return {
                ...state,
                quantity: state.quantity - 1,
                price: formatPrice({
                    amount: state.unitAmount,
                    currency: state.currency,
                    quantity: state.quantity - 1,
                }),
            };
        case 'setLoading':
            return { ...state, loading: action.payload.loading };
        case 'setError':
            return { ...state, error: action.payload.error };
        default:
            throw new Error();
    }
}

const OneTimePayment = () => {
    const location = useLocation()
    const classes = useStyles()
    const [state, dispatch] = useReducer(reducer, {
        quantity: 1,
        price: null,
        loading: false,
        error: null,
        stripe: null,
    });

    useEffect(() => {
        async function fetchConfig() {
            // Fetch config from our backend.
            const { publicKey, unitAmount, currency } = await fetch(
                'https://c3d-payment.netlify.app/.netlify/functions/server/config'
            ).then((res) => res.json());

            // Make sure to call `loadStripe` outside of a component’s render to avoid
            // recreating the `Stripe` object on every render.
            dispatch({
                type: 'useEffectUpdate',
                payload: { unitAmount, currency, stripe: await loadStripe(publicKey) },
            });
        }
        fetchConfig();
    }, []);

    const handleClick = async (event) => {
        // Call your backend to create the Checkout session.
        dispatch({ type: 'setLoading', payload: { loading: true } });
        console.log(await fetchCheckoutSession())
        const { sessionId } = await fetchCheckoutSession();
        console.log("session id: ", sessionId);
        // When the customer clicks on the button, redirect them to Checkout.
        const { error } = await state.stripe.redirectToCheckout({
            sessionId,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error) {
            dispatch({ type: 'setError', payload: { error } });
            dispatch({ type: 'setLoading', payload: { loading: false } });
        }
    };

    return (
        location.pathname === '/java' ?
            <GeneralBtn link={handleClick}
                disabled={!state.stripe || state.loading} label="Purchase full lesson plan" />
            :
            <a className={classes.alink} onClick={handleClick}>purchase now
            </a>
        // <div className="sr-root">
        //     <div className="sr-main">
        //         <header className="sr-header">
        //             <div className="sr-header__logo"></div>
        //         </header>
        //         <section className="container">
        //             <button
        //                 role="link"
        //                 onClick={handleClick}
        //                 disabled={!state.stripe || state.loading}
        //             >
        //                 {state.loading || !state.price
        //                     ? `Loading...`
        //                     : `Buy for ${state.price}`}
        //             </button>
        //             <div className="sr-field-error">{state.error?.message}</div>
        //         </section>
        //     </div>
        // </div>
    );
};

export default OneTimePayment;
