import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Pricingplan from "../cards/Pricingplan"

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        // width: "100%",
        textAlign: "center",
        margin: "0 4rem 0 4rem",
        [theme.breakpoints.down('md')]: {
            margin: '0 1rem 0 1rem',
        }
    },
    header: {
        textAlign: "center",
        margin: "1rem 0",
    },
    desc: {
        margin: "1rem 0 1rem 0",
    },
    btn: {
        position: 'relative',
        backgroundColor: "#11D3BC"
    },
    // javabtn: {
    //     textDecoration: 'underline',
    //     position: 'absolute',
    //     left: '30%',
    //     top: '70%'
    // }
}));

const Pricinghero = props => {
    const classes = useStyles();
    return (

        <Grid item xs={12} className={classes.root}>
            <ThemeProvider theme={theme}>
                <h1 className={classes.header}>{props.header}</h1>
                <p className={classes.desc}>{props.description}</p>
            </ThemeProvider>

            <Grid container style={{ justifyContent: 'center' }}>
                {props.options.map((item, index) => {
                    return (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                            <div onClick={(e) => props.handler(e, index)} style={{ display: 'flex' }}>
                                <Pricingplan {...item} id={index} selected={props.selected} />
                            </div>
                        </Grid>
                    )
                }

                )}
            </Grid>

        </Grid>

    )
}

export default Pricinghero