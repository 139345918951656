import React from "react"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Grid from "@material-ui/core/Grid"
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import GeneralBtn from "../elems/GeneralBtn"
import Python from "../Python"
import OneTimePayment from "../OneTimePayment"

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles((theme, props) => ({
    root: {
        textAlign: 'center',
        margin: '4rem',
        padding: '0 1% 1% 1%',
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            margin: '2rem 1rem 2rem 1rem',
        }
    },
    title: {
        // marginBottom: '2%',
        color: '#11D3BC',
    },
    content: {
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
        padding: '3% 8% 2% 8%',

        // [theme.breakpoints.down('md')]: {
        //     padding: '2%',
        // }

    },
    btn: {
        // marginTop: '2%',
        textAlign: "center"
    },
    link: {
        color: '#FC5185'
    },
    stylebtn: {
        color: '#11D3BC',
        backgroundColor: 'white',
        border: '1px solid #11D3BC'
    },
    form: {
        width: "90%",
        textAlign: "center"
    },
    txt: {
        width: "100%"
    },
    btnClass: {
        borderRadius: "5px",
        width: "100%",
        marginRight: "0 !important",
        marginTop: "1rem",
        backgroundColor: '#FC5185'
    },
    alink: {
        textDecoration: "none",
        color: '#FC5185',
        cursor: 'pointer',
    }

}));

const JavaPlan = props => {
    const classes = useStyles();
    const handleJavaClick = () => {
        window.open('https://java.c3d.io/');
    }

    return (
        <Grid className={classes.root} >

            <Grid container sx={8}>
                <Card className={classes.content} variant="outlined">
                    <CardContent>
                        <h2 className={classes.title}>
                            {props.title}
                        </h2>
                        <p>
                            {props.description}
                        </p>
                    </CardContent>
                    <div style={{ textAlign: "left", padding: '3% 8% 2% 8%', }}>
                        {props.details}
                    </div>
                    <div className={classes.btn}>
                        <GeneralBtn label="Access Now for Free" className={classes.btn} link={handleJavaClick} />
                    </div>
                    <div className={classes.btn} style={{ padding: '8%' }}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableBody>
                                    {props.plans.map((row, index) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.fee}</StyledTableCell>
                                            <StyledTableCell align="center" className={classes.link}>
                                                {
                                                    index === 0 ?
                                                        <a target="_blank" className={classes.alink} href="https://drive.google.com/file/d/10DkSjV3725-CzIZf5_aJRCuYxr7h-qOs/view">
                                                            {row.btnDesc}
                                                        </a>
                                                        :
                                                        <OneTimePayment />
                                                }
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Card>
            </Grid>
            <div style={{ textAlign: "center", width: "100%" }}>

                <Python />
            </div>
        </Grid >
    )
}

export default JavaPlan