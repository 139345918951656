
import coverWebp from "../assets/virtual-reality-coding-middle-school.webp";
import cover from "../assets/virtual-reality-coding-middle-school.jpg";

import jacob from "../assets/testimonial/jacob.png";
import aron from "../assets/testimonial/aron.png";

import frame15 from '../assets/empower/Frame15.png'
import frame15Webp from '../assets/empower/coding-lesson-tutorial-for-kid.webp'
import frame14 from '../assets/empower/Frame14.png'
import frame14Webp from '../assets/empower/vr-gallery.webp'
import frame12Webp from '../assets/empower/learning-analytics-in-coding-lessons.webp'
import frame12 from '../assets/empower/Frame12.png'
import frame16 from '../assets/empower/Frame16.png'
import frame16Webp from '../assets/empower/coding-lesson-plans-easy.webp'
import casestudy from '../assets/empower/coding-tool-for-middleschool.webp'


import touch from '../assets/features/touch.png'
import homework from '../assets/features/homework.png'
import marketing from '../assets/features/marketing.png'
import password from '../assets/features/password.png'
import school from '../assets/features/school.png'
import student from '../assets/features/student.png'


const data = {
    hero: {
        header: "Use VR to teach coding.<br/> Fun for students, easy for teachers",
        description: "With C3D School, teaching students to code can be just as fun as an art lesson! We work to empower educators teaching children to code for the first time.",
        btnLink: {
            text: "Try java version",
            link: ""
        },
        button: "Get a Quote",
        link: "",
        imgWebp: coverWebp,
        img: cover,
        alt: "cover",
        id: "teacher"
    },
    chooseCard: {
        title: "Choose what’s best for your classroom",
        plans: [
            {
                title: "C3D School",
                body: "Perfect for elementary to middle school and elementary school teachers without computer science background",
                link: ""
            },
            {
                title: "C3D Java",
                body: "For high school(AP CS A) and post-secondary teachers looking to engage students in APCS",
                link: ""
            }
        ]
    },
    video1: {
        // header: "Children are hooked to coding with VR outcomes",
        link: "https://www.youtube.com/embed/HX_t4S9dqkY"
    },
    video2: {
        // header: "See how C3D Makes Teaching Coding Easy",
        link: "https://www.youtube.com/embed/b_Ro16hGyIU"
    },
    empower:
    {
        header: "C3D School: How we empower educators",
        body: "Our solution makes it easy for teachers without a Computer Science background to engage with and deliver an exciting coding curriculum. With the C3D School license, teachers are equipped with the right resources to be facilitators.",
        lists: [
            {
                img: frame15,
                imgWebp: frame15Webp,
                title: "Interactive self-learning coding lessons",
                body: "No coding knowledge? No problem! C3D comes with self-learning coding lessons students can follow through. You can access student’s creation from the teacher dashboard and give feedback."
            },
            {
                img: frame12,
                imgWebp: frame12Webp,
                title: "Easy Class Management",
                body: "The C3D platform makes coding lessons engaging for students allowing teachers to fully focus on giving guidance and tracking the progress of their students. The C3D teacher dashboard allows you to track student’s progress on self-learning modules and also to assign homework assignments."
            },
            {
                img: frame14,
                imgWebp: frame14Webp,
                title: "Examples and Resource Videos",
                body: "To give you ideas for theme of VR project, we have example worlds with code available on C3D teacher dashboard. You will have access to many short resource videos which you can refer to."
            },
            {
                img: frame16,
                imgWebp: frame16Webp,
                title: "Curriculum design/lesson plan",
                body: "The C3D school license comes with all the necessary on-boarding materials, lesson plans, handouts and lesson videos. You can download the material from C3D teacher dashboard included in C3D school license."
            }
        ]

    }
    ,
    features:
    {
        header: "Features for a stress-free teaching experience",
        body: "With C3D School, teaching your students to code in the classroom becomes a breeze. Sign up for a self-learning curriculum and stress-free coding education",
        lists: [
            {
                img: touch,
                title: "Interactive self-learning coding lesson modules",
                body: "Students can learn coding concepts by following 9 project-based interactive coding lessons designed by expert computer science teachers. Smart code check ensures students learn the key coding concepts."
            },
            {
                img: marketing,
                title: "Progress Tracking Chart",
                body: "	At a glance, you can see all your students progress. You can click the progress indicator to see actual student’s work. This allows teachers to see how well their students are progressing with the coding lessons."
            },
            {
                img: homework,
                title: "Assignment feature",
                body: "	Giving a coding assignment is easy with C3D school. You can enter title and description and then the assignment will appear on student portal. You can add comment and feedback on student's creation."
            },
            {
                img: student,
                title: "Personalized Student Portal",
                body: "Each student has a personalized portal where they can see their progress and access assignments"
            },
            {
                img: password,
                title: "Premium Privacy Protection",
                body: "We care about students privacy. With C3D school account, you can teach students to code by signing up as a classroom and create profile for students without using their identifiable information."
            },
            {
                img: school,
                title: "Curriculum Resources",
                body: "Curriculum content include example VR worlds, lesson plans and worksheets in addition to C3D teacher dashboard which allows you to check student’s work, leave comments and track student progress."
            }
        ]
    },
    testimonial: {
        header: "Hear what our users say",
        para:
            "We are receiving great reviews from C3D users in classrooms in private and public schools, in after school programs and at home.",
        id: "testimonial",
        papers: [
            {
                // paper 1
                header:
                    "",
                para:
                    "“C3D is a wonderful way to blend programming concepts with artistic creativity. It provides a framework for teachers to create engaging curriculum that is relevant and cutting-edge. The lesson plans were clear, concise and effective.”",
                img: jacob,
                alt: "Crofton House School",
                name: "Jacob Tran",
                description: "Crofton House School teacher"
            },
            //paper 2
            {
                header:
                    "",
                para:
                    "“My son loved these online coding classes! He is a beginner coder and was thoroughly engaged throughout your whole session. Great job!”",
                img: aron,
                alt: "Gleneagles Ch'axay Elementary School",
                name: "Aron Campbell",
                description: "Gleneagles Ch'axay Elementary School Principal"
            }
        ]
    },
    learningOutcomes: {
        header: ["Use Case and Learning Outcomes"],
        body: [
            {
                title: "Case Study - Gleneagles Ch’axay Elementary School",
                description: "In February 2019, grade 6/7 students in three classrooms in a school in Gleneagles Ch’axay Elementary School were introduced to coding concepts such as variables in 3-week course. The target learning outcome was for students to showcase their learning in science inquiry theme in VR animation using their imagination and artistic creativity while also learning computational thinking and problem solving skills. The engagement level in the classroom was very high. Teachers were very happy to see increased learning outcomes compared to existing coding platforms and achieved learning outcomes. Some students imported their own 3D models into C3D.io VR world.",
                link: "",
                imgUrl: casestudy
            },
            {
                title: "Teacher Blog",
                description: "Take a look at teacher's blog:<br/><br/><a href='https://dl.acm.org/doi/abs/10.1145/3209635.3209636'>Go to Blog</a>",
                link: "https://dl.acm.org/doi/abs/10.1145/3209635.3209636"
            }, {
                title: "Newsletter",
                description: "Receive special lesson plans, news and resources.",
                link: ""
            }
        ]
    },
    faqs: [
        {
            question: "What is C3D?",
            answer: `C3D is a tool on the web where you can create your own VR world while also learning to code. It's an innovative and engaging way to learn coding. You'll understand the fundamental concept of coding before you know it.
            <br/><br/>With our interactive lessons, kids can self-learn. So, teachers or parents don't need computer science knowledge to teach coding! `
        },
        {
            question: "How can I use C3D in my classroom?",
            answer: `To use C3D in your classroom, you can use C3D School that has a dashboard for you to see each student's progress, manage assignments and get curriculum resources. To learn more,  Just drop us a line at <a mailto:info@c3d.io>info@c3d.io</a>.`
        },
        {
            question: "Which grades is this platform suitable for?",
            answer: `C3D is suitable for students used to using computers including mouse, trackpad and keyboard. We recommend C3D to grade 6 and above, but some schools also use C3D in grade 4 and 5 classrooms.`
        },
        {
            question: "What do we need to use C3D in the classroom?",
            answer: `To use C3D, you need a minimum of one computer per two students. The computer needs a web browser (Google Chrome is recommended) and stable internet connection.
            <br/><br/>To see the result of your code in 360 VR, you can either use our full-screen mode on computer OR a smartphone with a web browser and a VR cardboard. With our new C3D WebVR, you can also experience your creation using Oculus Browser on Oculus Quest 2. (Oculus Quest is trademark of Facebook Technologies, LLC)
            <br/><br/>VR cardboard is optional. If you want one, we can bundle them to our school license. Just let us know at <a mailto:info@c3d.io>info@c3d.io</a>.`
        },
        {
            question: "Do we need VR headsets to use C3D?",
            answer: `No, you don't.
            <br/><br/>C3D has a full-screen view that can substitute VR experience with goggles.
            <br/><br/>That said, VR cardboard headset does enhance the experience. If you want one, we can bundle cardboard headsets to our school license.`
        },
        {
            question: "What learning outcomes can we expect? ",
            answer: `Students will learn to think in 3D using a 3D coordinate system, design a 3D world that conveys a message along with coding concepts. You can expect students to achieve learning outcomes such as computational thinking, problem solving and coding fundamentals with much more excitement and engagement in the process. Above all, students will find coding as something fun and exciting. The coding concepts will stick more with C3D compared to existing 2D coding platforms. `
        },
        {
            question: "How long is the license valid?",
            answer: `C3D School license is valid for one year from the day we deliver your C3D School site portal. If you need a license for a different duration, please contact us at info@c3d.io. Seats are re-assignable to other students while the license is valid. Discount is available for multi-year license purchase.`
        },
        {
            question: "How many hours a week should I plan on using C3D?",
            answer: `There are two options.
            <br/><br/>If your goal is to onboard students to coding, we recommend you allocate one hour each for the first three interactive self-learning modules designed for beginners and then two hours each for following intermediate modules. If you do one class per week, students will have created 8 VR worlds while learning fundamental coding concepts after 8 weeks, and will be ready to code more VR worlds on their own.
            <br/><br/>If you are using the tool for a cross-curricular project, we recommend you spend two hours having students do one or two beginner modules to learn how C3D works before moving on to creating their own VR presentation to showcase their learning in other subjects or create a 3D world to tell a story. `
        },
        {
            question: "How many students are included in a license?",
            answer: `Our most popular classroom license includes up to 30 students. You can also add more students to your classroom. The school-wide license and district license has different numbers of seats. To learn more, drop us a line at <a mailto:info@c3d.io>info@c3d.io</a>.`
        },
        {
            question: "Is a set assigned to a student re-assigned to another student?",
            answer: `Seats are re-assignable to other students while the license is valid. When a seat is assigned to a new student, the previous student can lose access to their VR world. We recommend you get seats for all students.`
        },
        {
            question: "What is the system requirements?",
            answer: `C3D works on most computers with a web browser. It even works on Chromebooks. That said, just like any other VR app, your computer needs to render three dimensional objects all the time. We recommend the following system for better experience.
            <br/><br/>OS: Windows 8, 10, 64-bit versions only; Mac OS X 10.13+. CPU: Intel Core i7 or i5, 5th generation or later
            <br/><br/>Although we have not heard <b>any</b> complaints from our users about speed so far, remember you are using the network to download and upload 3D models. Your network speed may affect loading of your VR world if you have multiple large 3D objects.
            <br/><br/>If you plan to create a really complex VR world with many 3D objects and animations, we recommend that you get a computer with a processor and a graphic card with the latest and strong capability.
            `
        },
        {
            question: "Can I use C3D with Oculus Quest 2?",
            answer: `Yes. We have a new product, C3D WebXR, which works with Oculus Browser on Oculus Quest 2. Contact us to find out more about this product.`
        },
        {
            question: "Can I try C3D school?",
            answer: `Yes. We have a trial site with limited features available. Please contact us to get access to the trial site.`
        },
        {
            question: "What is the difference between C3D school and C3D Java version? ",
            answer: `C3D is a block-based programming platform for K-12 schools to introduce coding to students with excitement by having VR as an outcome of their code. It is also for individual learners who are either beginners or intermediate coders. The block programming interface is something many children are already familiar with, while creating a VR world makes learning to code very fun and engaging. Children who are introduced to coding with C3D have less chance of losing interests in coding.
            <br/><br/>On the contrary, C3D Java version is mainly used by Advanced Placement Computer Science (APCS A) teachers at high school and introductory level computer science teachers at post-secondary institutions. This is a tool that has transformed Java class. CS teachers have loved this platform for over a decade.`
        }
    ],
    quote: {
        text: '“When students are allowed to be creative, such as making VR content they identify with, they absorb coding concepts along the way.  - Jason Madar”'
    }
};

export default data;
