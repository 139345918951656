import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
// import CarouselPeople from "../CarouselPeople";
import Video from '../Video'

const styles = (theme, prop) => ({
    root: {
        // marginTop: '3rem',
        // padding: '5%',
        // width: "100vw",
        display: "flex",
        "flex-direction": "column",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center"
    },
    planContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: '2rem',
        justifyContent: 'center'
    }

})
class SeeVideo extends Component {
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                <h2 style={{ textTransform: "capitalize", }}></h2>
                <Video {...data.video} />
            </div>
        )
    }
}

export default withStyles(styles)(SeeVideo)