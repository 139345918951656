// import headerImage from "../assets/learntocode.jpeg"
import headerImageWebp from "../assets/coding-lesson-for-kids.webp"
import headerImage from "../assets/coding-lesson-for-kids.jpg"

import learncode1 from "../assets/learncodeParents/learncode1.svg";
import learncode2 from "../assets/learncodeParents/learncode2.svg";
import learncode3 from "../assets/learncodeParents/learncode3.svg";
import learncode4 from "../assets/learncodeParents/learncode4.svg";
import learncode5 from "../assets/learncodeParents/learncode5.svg";
import learncode6 from "../assets/learncodeParents/learncode6.svg";
import onlineliveclassWebp from '../assets/fun-coding-class-online.webp'
import learncodingvideoWebp from '../assets/learn-coding-video.webp'
import onlineliveclass from '../assets/fun-coding-class-online.jpg'
import learncodingvideo from '../assets/learn-coding-video.png'

import priceMonth from "../assets/programPricemonthly.svg";
import priceYear from "../assets/programPriceYearly.svg";

import portal from "../assets/childLearnParents/c3dportal.svg";
import tutor from "../assets/childLearnParents/c3dtutorialParents.svg";

import img1 from "../assets/threeStepsParents/threeSteps1.svg";
import img2 from "../assets/threeStepsParents/threeSteps2.svg";
import img3 from "../assets/threeStepsParents/threeSteps3.svg";
import parent1 from "../assets/avatar/undraw_female_avatar_w3jk.png"
import parent2 from "../assets/avatar/undraw_profile_pic_ic5t.png"
import parent3 from "../assets/avatar/undraw_male_avatar_323b.png"

import zombie from "../assets/galleryParents/zombie.png";
import wcip from "../assets/galleryParents/we_come_in_peace.png";
import spaceship from "../assets/galleryParents/spaceship.png";
import horror from "../assets/galleryParents/horror.png";
import airport from "../assets/galleryParents/airport.png";
import airplane from "../assets/galleryParents/airplane.png";

import jacob from "../assets/testimonial/jacob.png";

const data = {
  hero: {
    imgWebp: headerImageWebp,
    img: headerImage,
    header: "Fun and Engaging Coding Classes and Lessons for Kids",
    description: "Give a gift of future skills to your child. Ignite their passion in coding with VR. A door to virtual reality education for your child",
    button: "Sign Up",
  },
  LearnToCode: {
    title: "Learn To Code Online with C3D",
    description: "C3D is an innovative platform that provides coding classes and lessons for kids using cutting-edge WebXR technology. Your child can learn programming while making their own VR games and animations, which they can enter and experience in 360-degree VR using VR headsets.",
    grid: [
      {
        img: learncode1,
        title: "Self-paced Learning",
        body: "C3D comes with interactive self-learning video tutorials allowing each student to pace their own learning journey. This allows children to take their time in learning the coding concepts needed to create their VR world.",
      },
      {
        img: learncode2,
        title: "Real-time Updates",
        body: "The C3D preview window renders any code changes made by the child instantly. Children can watch their VR world develop in real-time further adding to the interactive online coding experience.",
      },
      {
        img: learncode3,
        title: "Interactive Learning Experience",
        body: "C3D's lesson modules provide an interactive learning experience. Children are guided step by step through the coding process using video lessons and frequent code checks.",
      },
      {
        img: learncode4,
        title: "Engaging Learning Material",
        body: "Children today are engaging with computer games, virtual reality and 3D animation on a daily basis. Being able to create their own VR games and animations is an added motivator to keep them engaged in coding.",
      },
      {
        img: learncode5,
        title: "Small Class Size",
        body: "If your kid prefers learning VR coding in an online class, we set a limit of 8 students per class to support each student's learning.",
      },
      {
        img: learncode6,
        title: "Future-minded",
        body: "As C3D uses cutting-edge technology to enable VR programming on a web browser, no need for any extra software. Be one of the first children to take part in this innovative VR coding experience!",
      }
    ],
  },
  video: {
    // header: "See how it works",
    link: "https://www.youtube.com/embed/HX_t4S9dqkY" 
  },
  MonthlySub: {
    title: "Our Monthly Subscription Package",
    subtitle: "Highlights",
    learn: [
      "Project-based self-learning modules to learn coding concept while making exciting VR animation",
      "Access to hundreds of animatable 3D models",
      "Unlimited use of C3D application to create your own VR world and games",
      "Discount to live online classes",
    ],
    programs: [
      {
        img: priceMonth,
        time: "per month",
        button: "sign up"
      },
      {
        img: priceYear,
        time: "per year",
        button: "sign up"
      }
    ],
  },
  ChildLearn: {
    title: "What will your child learn?",
    subtitle1: "The Coding Fundamentals",
    description: "Children will learn and understand the fundamental coding concepts needed to code in any programming language, such as: ",
    learn: ["Variables", "Objects", "Loops", "Functions", "3D Coordinate Systems"],
    subtitle2: "Advanced Coding Skills",
    link: {
      href: "https://www.linkedin.com/company/codeca-education/",
      caption: "Learn more about our Java version",
    },
    img1: portal,
    img2: tutor,

  },
  SignUpPromotion: {
    title: "Ready to sign up now?",
    description: "Sign up for your monthly subscription today!",
    button: "Get Started",
  },
  ThreeSteps: {
    title: "C3D in 3 Easy Steps",
    box: [
      {
        img: img1,
        caption: "Drag and drop code blocks to create your virtual world"
      },
      {
        img: img2,
        caption: "Scan the QR code with your phone"
      },
      {
        img: img3,
        caption: "Expore your VR world! *"
      }
    ],
    description: "* Use of phone and cardboard headsets or standalone VR headsets is optional. C3D has a full-screen mode for you to enjoy your VR world on a computer screen.",

  },
  ProjectGallery: {
    title: "Browse Our Project Gallery",
    description: "VR worlds developed by our young creators",
    // bullets: [
    //   "Scan the QR code with your phone",
    //   "Tap on the cardboard icon and hold your phone horizontally",
    //   "Put your phone in the cardboard headset and enjoy!",
    // ],
    gallery: [
      {
        img: zombie,
        title: "Zombie"
      },
      {
        img: wcip,
        title: "We come in peace"
      },
      {
        img: spaceship,
        title: "Spaceship"
      },
      {
        img: horror,
        title: "Horror"
      },
      {
        img: airport,
        title: "Airport"
      },
      {
        img: airplane,
        title: "Airplane"
      }
    ],
  },
  OnlineClass: {
    title: "Want to learn C3D in an online class? Check out our online class offerings:",
    description: "30% off with your subscription",
    courses: [
      {
        title: "Online Live Lessons",
        imgUrlWebp: onlineliveclassWebp,
        imgUrl: onlineliveclass,
        description: "An experienced instructor will teach your child how to code VR games or animations in a small virtual class setting using Zoom",
        button: "Choose a class"
      },
      {
        title: "Recorded Online Coding Classes",
        imgUrl: learncodingvideo,
        imgUrlWebp: learncodingvideoWebp,
        description: "If your schedule does not meet the live online lesson schedule, you can learn to code online with recorded classes. A professional experienced instructor will walk you through every step you need to know to code your VR world.",
        button: "Coming soon",
      }
    ]
  },
  ppl: {
    header: "Hear from our happy customers",
    subheader: "When students are allowed to be creative, such as making VR content they identify with, they absorb coding concepts along the way.<br/> Jason Madar",
    lists: [
      {
        title: "C3D is great! My son, Kai, was very engaged in the VR game class. He was so proud to show me the interactive game he programmed. This is a skill he can use in the future! I have signed him up for another coding class!",
        img: parent1, //TODO
        name: "Amy Nishimura"
      },
      {
        title: "My son loved these online coding classes! He is a beginner coder and was thoroughly engaged throughout your whole session. Great job!",
        img: parent2, //TODO 
        name: "Rachel Givon"
      },
      {
        title: "My son loved the online coding class, It was great! We have a cardboard headset, and it worked right away. Very impressive! Many thanks! ",
        img: parent3, //TODO 
        name: "Natalie Dunn"
      },
      // {
      //   title: "Thank you! This was awesome!",
      //   img: parent1, //TODO 
      //   name: "Ariana"
      // }
    ]
  },
  Faq: [
    {
      question: "Do I need VR headsets to use c3d?",
      answer: "<b>It's nice to have, but not a must.</b></br>C3D has a full-screen view that can substitute VR experience with VR goggles or headsets. <br/>Note C3D has two versions: C3D for smartphone and VR cardboard headsets, and C3D WebXR for Oculus Quest 2 headsets.<br/>"
    },
    {
      question: "What equipment do I need to use C3D?",
      answer: "To use C3D, you need a computer with access to a  web browser (Chrome), so if you are viewing this webpage on a computer you are already set to start your coding journey with C3D.io!<br/><br/>To see the result of your code in 360 VR, you can either use our full-screen mode on computer OR a smartphone with a web browser (we recommend using Google Chrome) and a VR cardboard OR Oculus Quest 2 VR headsets. ",
    },
    {
      question: "Is a high-end computer needed to use C3D.io?",
      answer: "C3D.io works on most computers and Chromebooks with a web browser. That said, just like any other VR app, your computer needs to render three dimensional objects all the time. We recommend the following system for a better experience.<br/><br/>OS: Windows 8, 10, 64-bit versions only; Mac OS X 10.13+. CPU: Intel Core i7 or i5, 5th generation or later<br/><br/>Your network speed may, however, affect the loading time of your VR world if you have multiple large 3D objects.<br/><br/>If you plan to create a really complex VR world with many 3D objects and animations, a computer with a processor and a graphic card with a  strong capability won’t hurt.",
    },

  ],
  quote: {
    text: '“When students are allowed to be creative, such as making VR content they identify with, they absorb coding concepts along the way.  - Jason Madar”'
  }


}

export default data;