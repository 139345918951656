import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({

    button: {
        textAlign: 'center',
        marginTop: "1vw",
        fontWeight: '900',
        color: "#FFFFFF",
        backgroundColor: "#FC5185",
        border: "none",
        borderRadius: "10px",
        minWidth: "168px",
        width: "auto",
        height: '34px',
        marginBottom: "8%!important",
        "&:hover": {
            backgroundColor: "#bf4d6f",
        },
        [theme.breakpoints.up('600')]: {
            marginRight: '8%',
            float: 'right'
        }
    },
    input: {
        width: '80%',
        margin: '3%',
        padding: '2%',
        border: '0.5px solid rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        height: '34px'
    }

}))


const SubscriptionForm = (props) => {
    const classes = styles()
    const [status, setStatus] = useState(null);
    const MY_FORM_ID = props.formId
    const YOUR_FORM_URL = `https://app.convertkit.com/forms/${MY_FORM_ID}/subscriptions`
    // useEffect(() => {
    //     axios.get(`https://api.convertkit.com/v3/tags?api_key=c8dhgmr5ycgRhSPMNPAq6A`)
    //         .then(res => {
    //             setTags(res.data.tags)
    //         })
    //         .catch(err => console.log(err))
    // }, [])

    // console.log(tags)
    // useEffect(() => {
    //     setTagMap(tags.reduce((result, tag) => {
    //         result[tag.name] = tag.id;
    //         return result;
    //     }, {}))
    // }, [tags])




    const handleSubmit = async (e) => {
        console.log(e.target)
        e.preventDefault();
        const data = new FormData(e.target);

        try {
            const response = await fetch(
                YOUR_FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );

            const json = await response.json();

            if (json.status === 'success') {
                console.log('subscription:', status)
                setStatus('SUCCESS');
                return;
            }
            setStatus('ERROR');
        } catch (err) {
            setStatus('ERROR');
        }
    };

    return (
        <div>
            {status === null &&
                <form
                    action={YOUR_FORM_URL}
                    method="post"
                    onSubmit={handleSubmit}
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                >
                    <input
                        className={classes.input}
                        label="Name"
                        name="fields[first_name]"
                        aria-label="Your name"
                        type="text"
                        // fullWidth
                        required
                        // margin="normal"
                        placeholder="Your name"
                    />
                    <input
                        className={classes.input}
                        label="Your email address"
                        name="email_address"
                        aria-label="Your email"
                        type="email"
                        required
                        margin="normal"
                        placeholder="Your email address"
                    />
                    <button
                        type="submit"
                        className={classes.button}
                    >
                        Subscribe
      </button>

                </form>}
            {status === 'SUCCESS' && <p>Thank you for signing up!</p>}
            {status === 'ERROR' && <p>Oops, try again.</p>}
        </div>

    );
};





// import React from "react";
// import ConvertKitForm from 'convertkit-react'
// import { ValidationError, useForm } from '@statickit/react';

// const MY_FORM_ID = 852453

// const Subscribe = (props) => {
//     const [state, handleSubmit] = useForm(MY_FORM_ID);
//     if (state.succeeded) {
//         return <div>Thank you for signing up!</div>;
//     }

//     return (
//         <form onSubmit={handleSubmit}>
//             <label htmlFor="email">Email</label>
//             <input type="email" name="email" required />
//             <ValidationError field="email" prefix="Email" errors={state.errors} />
//             <button type="submit" disabled={state.submitting}>Sign up</button>
//         </form>
//     )


//     // console.log('footer', props.location)
//     // return (
//     //     <ConvertKitForm formId={MY_FORM_ID} />
//     // )
// }

export default SubscriptionForm
