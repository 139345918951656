import cover from '../assets/coding-tool-for-middleschool.webp'
import coverWebp from '../assets/casestudies/case1.png'
import grade4 from '../assets/grades/elementary-school-coding.png'
import grade4Webp from '../assets/grades/elementary-school-coding.webp'
import grade6 from '../assets/grades/hour-of-code-lesson-plan.png'
import grade6Webp from '../assets/grades/hour-of-code-lesson-plan.webp'
import grade7Webp from '../assets/grades/middle-school-coding.webp'
import grade7 from '../assets/grades/middle-school-coding-grade7.jpg'
import grade9 from '../assets/grades/high-school-vr-coding.jpg'
import grade9Webp from '../assets/grades/high-school-vr-coding.webp'



const data = {
    hero: {
        header: 'Bring excitement to Coding with <b>C3D</b>',
        title: 'HOUR OF CODE <b>LESSON PLANS</b>',
        btn: 'Choose Lesson Plans',
        img: cover,
        imgWebp: coverWebp
    },
    plans: [
        {
            grade: 'Grade 4-7',
            title: 'Intro to VR Coding',
            subtitle: 'What is VR and what is 3D Coordinate system?',
            content: '1 lesson - 90 minutes',
            btn: 'Download Lesson Plan',
            img: grade4,
            imgWebp: grade4Webp

        },
        {
            grade: 'Grade 6-8',
            title: 'Intro to VR Animation',
            subtitle: 'How can I move an object in three dimensional space?',
            content: '1 lesson - 120 minutes',
            btn: 'Download Lesson Plan',
            img: grade6,
            imgWebp: grade6Webp

        },
        {
            grade: 'Grade 7-10',
            title: 'Interactive 3D animation',
            extraInfo: 'This lesson is suitable for students with previous experience with block coding. The lesson explores both exciting and challenging aspects of bringing interactivity in a 360-degree virtual reality setting.',
            subtitle: 'User interaction as a trigger for animation',
            content: '180 minutes',
            btn: 'Download Lesson Plan',
            img: grade7,
            imgWebp: grade7Webp

        },
        {
            grade: 'Grade 9-12',
            title: '3D modeling and Interactive animation',
            extraInfo: 'This lesson is suitable for high school students with a fair amount of experience in coding. The lesson explores both the effectiveness and the challenges of telling a story in a 360-degree virtual reality setting',
            subtitle: 'How can I use my own 3D model to effectively convey a message in VR animation?',
            content: '5 hours',
            btn: 'Download Lesson Plan',
            img: grade9,
            imgWebp: grade9Webp

        },
    ]
}

export default data