import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  root: {
    maxWidth: "75em",
    margin: "auto",
    padding: "60px 0 0 0",
  }
})

const CenteredHeader = props => {
  const classes = props.classes

  return props.header !== "null" || props.para !== "null" ? (
    <div className={classes.root} id={props.id}>
      {props.header ? (
        <h2 align="center">
          {props.header}
        </h2>
      ) : (
          ""
        )}
      {props.para ? (
        <body align="center">
          {props.para}
        </body>
      ) : (
          ""
        )}
    </div>
  ) : (
      ""
    )
}

export default withStyles(styles)(CenteredHeader)
