import React, { useRef, useEffect } from 'react'
import HeroLeftText from '../parts/HeroLeftText'
import data from "../../data/teachers"
// import Webinar from '../parts/teachers/Webinar'
import ChoosePlan from '../parts/teachers/ChoosePlan'
import EmpowerTeacher from '../parts/teachers/EmpowerTeacher'
import Features from '../parts/Features'
import TeacherTestimonial from '../parts/teachers/TeacherTestimonial'
import LearningNestedList from '../parts/teachers/LearningNestedList'
import FAQ from '../parts/FAQ'
import GeneralBtn from '../parts/elems/GeneralBtn'
import ContactForm from '../parts/ContactForm'
import { makeStyles } from '@material-ui/core'
import VideoBtn from '../parts/teachers/VideoBtn'
import Quote from '../parts/Quote'
import Video from '../parts/Video'
import WebXR from "../parts/landing/WebXR.js";
import SeeVideo from '../parts/parents/SeeVideo'
import FutureOfVR from "../parts/landing/FutureOfVR.js";



const styles = makeStyles((theme) => ({
    btn: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem'
        }
    }
}))

const scrollToRefObject = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })

const Teachers = () => {
    const classes = styles()
    const contactRef = useRef(ContactForm)
    const schoolInfoRef = useRef(EmpowerTeacher)
    const executeScroll = () => scrollToRefObject(contactRef)

    const routeToSchool = () => scrollToRefObject(schoolInfoRef)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div style={{
            textAlign: 'center',
            alignItems: "center"
        }}  >

            <HeroLeftText {...data.hero} linkTeacherContact={executeScroll} />
            <div style={{
                textAlign: 'center', display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }} >
            </div>
            <WebXR />
            <SeeVideo {...data.video} />
            <FutureOfVR {...data.video2} />

            {/* <Webinar /> */}
            <ChoosePlan {...data.chooseCard} linkSchoolInfo={routeToSchool} />
            <div ref={schoolInfoRef} >
                <EmpowerTeacher {...data.empower} />
            </div>
            <Features {...data.features} />
            <VideoBtn />
            <GeneralBtn stylebtn={classes.btn} label="Get a Quote" link={executeScroll} />
            <TeacherTestimonial {...data.testimonial} />
            <LearningNestedList {...data.learningOutcomes} />
            <FAQ {...data.faqs} />
            <div style={{
                textAlign: 'center', display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}>

                <Quote {...data.quote} />
            </div>
            <div ref={contactRef} name="scroll-contactForm">
                <ContactForm />
            </div>


        </div>
    )
}

export default Teachers