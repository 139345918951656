import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"
import Subscribe from '../parts/Subscribe'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'



import { useMediaQuery } from "@material-ui/core";

import Logo from "../../assets/c3d_logo_on_black.svg";

import LogoWord from "../../assets/c3dlogowithword.svg"

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: "2rem 1rem",
    justifyContent: 'center'
  },
  box: {
    // margin: "0.5rem"
    //width: "80%",
    // padding: "1vw",
    textAlign: 'center',
    justifyContent: 'center'
  },
  linkbox: {
    width: "40%",
    padding: "1vw",
  },
  button: {
    padding: "0.5vw 3vw",
    backgroundColor: "#FC5185",
    borderRadius: "0.5vw",
    border: "none",
    color: "#FFFFFF",
    fontSize: "1.25vw",
    float: "right"
  },
  input: {
    width: "23vw",
    paddingTop: "0.7vw",
    paddingBottom: "0.7vw",
  },
  about: {
    marginTop: "3vw",
    marginBottom: "3vw",
  },
  media: {
    margin: "1vw",
  },
  links: {
    textDecoration: "none",
    color: "#333333",
    borderBottom: "0.5vw none transparent"
  },

});

const Footer = props => {
  const [formId, setFormId] = useState('852453');
  const history = useHistory()
  const location = useLocation()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = props.classes;

  const handleClickToS = (e) => {
    window.open('https://canada.c3d.io/terms-of-service.html');
  }

  function changeLink(e) {
    e.target.style.borderBottom = "0.2vw solid #11D3BC";
  }
  function revertedLink(e) {
    e.target.style.borderBottom = "0.2vw none transparent";
  }
  function changeSubmit(e) {
    e.target.style.padding = "1vw 3.3vw";
    e.target.style.fontSize = "1.4vw";
  }
  function revertedSubmit(e) {
    e.target.style.padding = "0.5vw 3vw";
    e.target.style.fontSize = "1.25vw";
  }
  const pages1 = [
    {
      name: "About",
      src: '/about',
    },
    // {
    //   name: "Case Studies",
    //   src: "/articles",
    // },
    {
      name: "Contact",
      src: "/school",
    },
    // {
    //   name: "FAQ",
    //   src: "/school",
    // },
    {
      name: "Privacy Policy",
      src: "/codeca-privacy-policy", //TO REPLACE
    },
    {
      name: "Terms of Service",
      src: "https://canada.c3d.io/terms-of-service.html", //TO REPLACE
    }
  ]
  const pages2 = [
    {
      name: "School License",
      src: "/school",
    },
    {
      name: "Personal Account",
      src: "/parents",
    },
    {
      name: "Java version",
      src: "/java", //TO REPLACE
    },
    {
      name: "Updates",
      src: "/articles", //TO REPLACE
    },

  ]
  const socialmedia = [
    //TO REPLACE
    {
      link: "https://www.linkedin.com/company/codeca-education",
      pic: Logo
    },
    {
      link: "https://www.linkedin.com/company/codeca-education",
      pic: Logo
    },
    {
      link: "https://www.linkedin.com/company/codeca-education",
      pic: Logo
    }
  ]

  useEffect(() => {

    switch (location.pathname) {
      case '/java':
        setFormId(1761741);
        break;
      case '/school':
        setFormId(1761739);
        break;
      case '/parents':
        setFormId(1761740);
        break;
      default:
        setFormId(852453);
    }
  })


  return (
    <Grid style={{ marginTop: '3rem', textAlign: 'center' }}>

      <div><hr color="#40514E" /></div>

      <Grid className={classes.container} >
        <Grid item md={4} sm={6} className={classes.box} style={isSmallScreen ? { order: 1 } : { order: 1 }}>
          <img src={LogoWord} alt="logo" />
          <p className={classes.about}>C3D.io is a product of Codeca Educational Technology and Services Inc., located at North Vancouver, BC, Canada</p>
          {/* {socialmedia.map(item => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={classes.media}
              href={item.link}
            ><img src={item.pic} alt="" /></a>
          ))} */}
        </Grid>
        <Grid container item md={4} sm={12} className={classes.box} style={isSmallScreen ? { order: 3, marginTop: '3%' } : { order: 2, marginTop: '3%' }}>
          <div className={classes.linkbox}>
            {pages1.map((item, index) => (
              <div key={index}>
                <a className={classes.links}
                  onMouseOver={changeLink}
                  onMouseLeave={revertedLink}
                  onClick={() => { item.name === "Terms of Service" ? handleClickToS() : history.push(item.src) }}
                  href=''
                >{item.name}<br /></a><br />
              </div>
            ))}
          </div>
          <div className={classes.linkbox}>
            {pages2.map((item, index) => (
              <div key={index}>
                <a className={classes.links}
                  onMouseOver={changeLink}
                  onMouseLeave={revertedLink}
                  href={item.src}
                >{item.name}<br /></a><br />
              </div>
            ))}
          </div>
        </Grid>
        <Grid item md={4} sm={6} className={classes.box} style={isSmallScreen ? { order: 2, } : { order: 3 }}>
          <h3>Subscribe to Our Newsletter</h3>
          <Subscribe formId={formId} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Footer);
