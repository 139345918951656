import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';



const styles = makeStyles((theme, prop) => ({
    root: {
        marginTop: '3rem !important',
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "1rem",
    },
    text: {
        [theme.breakpoints.up('md')]: {
            width: "40vw",
        },
        width: "80vw",
        textAlign: "center",
    },
    title: {
        [theme.breakpoints.up('md')]: {
            width: "30vw",
        },
        width: "70vw",
        textAlign: "center",
    },
    grid: {
        display: "flex",
        flexWrap: "wrap",
        padding: "5vw",

        alignItems: "center",
        justifyContent: "center",
    },
    bullet: {
        color: "#11D3BC",
    },
    list: {
        color: "#000000",
    },
    gridcontent: {
        [theme.breakpoints.up('md')]: {
            width: "30vw",
            padding: "0 5vw",
            width: "80%",
        },
        // width: "90%",

    },
}))

const Inspire = (props) => {

    const classes = styles()
    return (
        <div className={classes.root}>

            <h2 className={classes.title}>{props.header}</h2>
            <p className={classes.text}>{props.para}</p>

            <div className={classes.grid}>
                <div>
                    <img src={props.img} style={{ width: "100%", height: "auto" }} alt="inspire" />
                </div>

                <div className={classes.gridcontent}>
                    <h3 style={{ color: "#11D3BC" }}>{props.subheader}</h3>
                    <ul className={classes.bullet}>
                        {props.lists.map((item, index) => (
                            <li key={index} style={{ paddingBottom: "2vw" }}><span className={classes.list}> <div dangerouslySetInnerHTML={{ __html: item }}></div></span></li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Inspire