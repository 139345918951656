import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Video from '../../parts/Video'

const styles = makeStyles((theme, prop) => ({
    root: {
        marginTop: '3rem',
        display: "flex",
        "flex-direction": "column",
        //justifyContent: "center",
        alignItems: "center"
    },
}))
const VideoBtn = () => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const classes = styles(isSmallScreen)
    return (
        <div className={classes.root}>
            <Video link="https://www.youtube.com/watch?v=T6LgFkRiYas&feature=youtu.be" />
        </div>
    )

}

export default VideoBtn