import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from "@material-ui/core/Grid"
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    height: "100%",
    width: "100%",
    marginBottom: "3rem"
  },
  imgbg: {
    width: '100%',
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
    opacity: '0.4'
  },
  container: {
    // [theme.breakpoints.up('md')]: {
    //   position: 'absolute',
    //   left: '50%',
    //   top: '50%',
    //   transform: "translate(-50%, -50%)",
    //   textAlign: 'center'
    // },
    textAlign: 'center',
    // maxWidth: '80%',
    margin: '0 auto',
    padding: "15vw",
  },
  header: {
    position: 'relative',
    // maxWidth: '80%',
    textAlign: "center",

    color: "white",

  }
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const ACaseStudy = () => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [article, setarticle] = useState('')
  const [imgLink, setimgLink] = useState(1)
  const [authorLink, setAuthorLink] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [date, setDate] = useState('')
  const [imgUrl, setimgUrl] = useState('')
  const [author, setAuthor] = useState('')
  const [progress, setProgress] = React.useState(10);
  useEffect(() => {
    const hrefArr = window.location.href.split('/');
    const id = hrefArr[hrefArr.length - 1]
    // console.log(id)
    axios.get(`https://wp.c3d.io/wp-json/wp/v2/posts/${id}`)
      .then(res => {
        setarticle(res.data)
        setTitle(res.data.title.rendered)
        setContent(res.data.content.rendered)
        setimgLink(res.data.featured_media)
        setAuthorLink(res.data.author)
        setDate(res.data.date)
      })



  }, [])

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const getImageUrl = axios.get(`https://wp.c3d.io/wp-json/wp/v2/media/${imgLink}`);
    const getAuthor = axios.get(`https://wp.c3d.io/wp-json/wp/v2/users/${authorLink}`);

    Promise.all([getImageUrl, getAuthor]).then(res => {
      setimgUrl(res[0].data.media_details.sizes.full.source_url)
      setAuthor(res[1].data.name)
    });
  }, [imgLink, authorLink])



  // console.log(article)
  // const { id, title, date, slug } = article
  // console.log(content)
  return (
    imgUrl ?
      <Grid container style={{ justifyContent: "center" }}>
        <Grid item xs={12} className={classes.root} style={{
          background: `linear-gradient(
            rgba(0, 0, 0,.5), 
            rgba(0, 0, 0,.5)
        ),url(${imgUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }}>
          {/* {isSmallScreen ? '' :
            <div style={{ backgroundColor: 'black', overflow: 'hidden' }}>
              <img className={classes.imgbg} src={imgUrl} alt="students sitting with teacher in class" />
            </div>
          } */}
          <Grid item xs={12} className={classes.container}>
            <ThemeProvider theme={theme}>
              <h1 className={classes.header}>{title}</h1>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid item xs={9} className={classes.root}>
          <Typography variant="body2" color="textSecondary" component="p">
            <p dangerouslySetInnerHTML={{ __html: content }}></p>
          </Typography>
          <Typography display="block" gutterBottom>
            {/* Author: {author} */}
          </Typography>
          <Typography display="block" gutterBottom>
            Date Posted: {date.substring(0, 10)}
          </Typography>
        </Grid>
      </Grid>
      :
      <div style={{ width: '100%', textAlign: 'center', height: '100ch' }}>
        <CircularProgressWithLabel value={progress} style={{ width: '100px', height: '100px', marginTop: '30vh' }} />
      </div>
  )

}
export default ACaseStudy








// import React from "react";
// import PropTypes from "prop-types";
// import { withStyles } from "@material-ui/core/styles";

// const styles = theme => ({
//   root: {
//     maxWidth: "75em",
//     margin: "30px auto"
//   }
// });

// const ACaseStudy = props => {
//   const { classes } = props;
//   return (
//     <div className={classes.root}>
//       <h1>Some name here</h1>
//     </div>
//   );
// };

// export default withStyles(styles)(ACaseStudy);
