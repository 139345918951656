import React, { useState, useEffect } from "react"
import FutureOfVR from "../parts/landing/FutureOfVR.js";
import WebXR from "../parts/landing/WebXR.js";
import Video from '../parts/Video'
import Inspire from "../parts/landing/Inspire.js";
import CompaniesAndPeople from "../parts/landing/CompaniesAndPeople.js";
import TopEducation from "../parts/landing/TopEducation";
import Innovative from "../parts/landing/Innovative.js";
import Linkto from "../parts/landing/Linkto"
import data from "../../data/landing.js";
import Cookie from "../parts/CookieConsent"
import SeeVideo from '../parts/parents/SeeVideo'


import VRFutureEducation from "../parts/VRFutureEducation.js";


const styles = theme => ({
  root: {
    maxWidth: "100vw",
    overflow: "hidden",
  },
  statistic_bg: {
    width: "100%",
    position: "absolute",
    "z-index": -1,
    marginTop: "-100px"
  },
  children_teacher_bg: {
    width: "100%",
    position: "absolute",
    "z-index": -1,
    marginTop: "-600px"
  },
});

const LandingPage = props => {
  // const [acceptCookie, setAcceptCookie] = useState()
  // localStorage.setItem('useCookie', acceptCookie)
  const consentPropertyName = 'jdc_consent'
  const shouldShowPopup = () => !localStorage.getItem(consentPropertyName)
  const handleChange = () => {
    // setAcceptCookie(true)
    // setAcceptCookie((state) => {
    //   console.log(state)
    //   localStorage.setItem('useCookie', state)
    //   // return state
    // })
    localStorage.setItem(consentPropertyName, true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="landing">
      <TopEducation />
      <WebXR />
      <SeeVideo {...data.video1}/>
      <FutureOfVR />
      <Innovative />

      <Inspire {...data.inspire} />
      {/* <Inspire {...data.futureEducation} /> */}
      <VRFutureEducation />
      <Linkto {...data.linkTo} />
      <CompaniesAndPeople />

      {shouldShowPopup() ?
        <Cookie onChange={() => handleChange()} />
        : ''
      }


    </div>

  );
};
export default LandingPage;
