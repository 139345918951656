import React, { Component } from "react";
import data from "../../../data/landing.js";
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Image, canUseWebP } from "react-img-webp";

const styles = (theme, prop) => ({
    root: {
        textAlign: 'center',
        // margin: '2%'
    },
    txtroot: {
        // width: "100vw",
        display: "flex",
        "flex-direction": "column",
        alignItems: "center",
        // [theme.breakpoints.up('md')]: {
        //     // marginTop: '8rem',
        // }
    },
    header: {
        textAlign: "center",
        width: "100%",
    },
    text: {
        textAlign: "left",
        color: "#FFFFFF",
    },
    title: {
        fontWeight: "600",
        color: "#FFFFFF",
        textAlign: "left",
    },
    box: {
        backgroundColor: "#40514E",
        [theme.breakpoints.down('md')]: {
            borderRadius: "10px",
            width: "80% !important"
        },
        borderRadius: "10px",
        margin: "2rem !important",
        "justify-content": "center",
        "align-items": "center",
        height: "20%",
        width: "80% !important",
        display: "inline-flex !important",
    },
    insideBox: {
        display: "flex",
        "flex-direction": "column",
        alignItems: "baseline",
        padding: "2rem",
        position: "relative"
    },
    arrowRight: {
        border: "solid #FFFFFF",
        "border-width": "0 0.15vw 0.15vw 0",
        display: "inline-block",
        padding: "0.2rem",

        transform: "rotate(-45deg)",
        "-webkit-transform": "rotate(-45deg)",
    },
    image: {
        width: '80% !important',
        marginTop: '3rem !important',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            position: 'absolute',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%,-50%)',
        },

    },
})
class AgeGroups extends Component {
    render() {
        const classes = this.props.classes
        function changeLink(e) {
            e.target.style.borderBottom = "0.2vw solid #11D3BC";

        }
        function revertedLink(e) {
            e.target.style.borderBottom = "0.2vw none transparent";
        }

        const scrollNowhere = () => ''
        return (


            <Grid container className={classes.root}>
                <h2 className={classes.header}>{data.ages.header}</h2>
                <Grid item xs={12} md={5} style={{ position: 'relative', textAlign: 'center' }}>
                    <Image src={data.ages.imgUrl} webP={data.ages.imgUrlWebp} className={classes.image} alt="Screenshot of the feature" />
                    {/* <img src={data.ages.imgUrl} className={classes.image} alt="Screenshot of the feature" /> */}
                </Grid>
                <Grid item xs={12} md={7} align="left" style={{ padding: '2%' }}>
                    <Grid container item xs={12} className={classes.txtroot}>
                        {/* complicated image here */}

                        {/* <ul> */}
                        {data.ages.lists.map((item, index) => (
                            // <li className={classes.box}>
                            <Grid key={index} container item xs={12} className={classes.box}>

                                <Grid item xs={12} className={classes.insideBox}>
                                    {/* change colour to black once picture is added */}
                                    <p className={classes.title}>{item.title}</p>
                                    <p className={classes.text}>{item.text}</p>
                                    <div onMouseOver={changeLink} onMouseLeave={revertedLink} style={{ position: "absolute", right: "5%", bottom: "5%" }}>
                                        <a href={item.src} className={classes.text} style={{ textDecoration: "none" }} onClick={index === 1 ? this.props.subscriptionLink : index === 2 ? this.props.subscriptionLink : scrollNowhere()}>{item.link}
                                            <i className={classes.arrowRight}></i></a>
                                    </div>

                                </Grid>
                            </Grid>
                            // </li>
                        ))}
                        {/* </ul> */}

                    </Grid>
                </Grid>
            </Grid>


        )
    }
}

export default withStyles(styles)(AgeGroups)