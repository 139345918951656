import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid, CardActions } from '@material-ui/core';
import GeneralBtn from "../parts/elems/GeneralBtn";
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from "@material-ui/core"
import { Image } from "react-img-webp";

const styles = makeStyles((theme, prop) => ({
    root: {
        margin: '5%',
        display: "flex",
        "flex-direction": "column",
        textAlign: "center",
        marginTop: '3rem',

    },
    button: {
        backgroundColor: "#FC5185",
        "&:hover": {
            backgroundColor: "#bf4d6f",
        }
    },
    img: {
        width: "80%",
        heigth: 'auto'
    },
    btnPosition: {
        justifyContent: "center",

    }
}))

const OnlineClassOffer = (props) => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("1025"))
    const handleClick4to7 = () => {
        window.open('https://docs.google.com/document/d/1vufbZcpyCrWtHqh65GAZXrtzKb4iUWfx0tPZb3Ems98/edit');
    }
    const handleClick6to8 = () => {
        window.open('https://docs.google.com/document/d/1Ja6HQp7yUAUXJ4Taki_jGBLSSKq3_aWggVimrH3dpKw/edit');
    }
    const handleClick7to10 = () => {
        window.open('https://docs.google.com/document/d/1yQSNloPgQfQANZ2zUn0fenpfnPdSxcUcDiPFZmKAb8Y/edit');
    }
    const handleClick9to12 = () => {
        window.open('https://docs.google.com/document/d/1kc31_DFwUjQNkoT3g-aUZ41Ia25VeLY2WayxJQheL58/edit');
    }

    const handleClickother = () => {
        window.open('/hour-of-code-lesson-plan');
    }

    const classes = styles()
    return (
        <div className={classes.root}>
            {/* online class offer */}
            <Grid container>

                <Grid container item xs direction="column">
                    <Grid container style={{ display: "flex", padding: "2vw 0" }}>
                        {props.plans.map((item, index) => (
                            <Grid key={index} item xs={12} md={6} style={{ display: "flex", width: "100%", }}>
                                <Card style={{ padding: "2vw", margin: "20px", position: "relative", flex: "1", minWidth: 200, minHeight: 250 }}>
                                    <CardContent style={{ padding: "1vw 0" }}>
                                        <h2>{item.grade}</h2>
                                        <h3 style={{ textTransform: 'uppercase' }}>{item.title}</h3>
                                        <Image src={item.img} webP={item.imgWebp} className={classes.img} alt="online classes envirenment" />
                                        {/* <img className={classes.img} src={item.imgUrl} alt="online classes envirenment" /> */}
                                        <p style={{ padding: "1vw 0" }}>{item.subtitle}</p>
                                        <p>{item.extraInfo}</p>
                                        <p>{item.content}</p>
                                    </CardContent>
                                    <CardActions style={{ marginTop: '25px', justifyContent: "center", }}>
                                        <div className={classes.btnPosition}>
                                            <GeneralBtn stylebtn={classes.button} label={item.btn}
                                                link={() => index === 0 ? handleClick4to7() : index === 1 ? handleClick6to8() : index === 2 ? handleClick7to10() : index === 3 ? handleClick9to12() : handleClickother()} />
                                        </div>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </Grid>

        </div>
    )

}

export default OnlineClassOffer