import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        marginTop: '5%',
        marginRight: '5%',
        marginLeft: '5%',
        // padding: '1% !important',
        // paddingLeft: '1%',
        // height: 'auto',
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
        minHeight: 100,
        width: 1000,
        cursor: 'pointer',
        // [theme.breakpoints.down('md')]: {
        //     minHeight: 200,
        // },
    },
    title: {
        fontSize: 14,
    },
    pos: {
        position: 'absolute',
        right: '1%',
        bottom: '1%',

    },

}));

const Pricingplan = props => {
    const [selectedIndex, setSelectedIndex] = useState(false)
    const classes = useStyles();


    useEffect(() => {
        if (props.selected === props.id) {
            setSelectedIndex(true)
        } else {
            setSelectedIndex(false)
        }
    })

    return (
        <Card
            className={classes.root}
            key={props.id}
            variant="outlined"
            style={selectedIndex ?
                {
                    boxShadow: "0px 0px 8px rgba(17, 211, 188, 0.25)",
                    borderColor: '#11D3BC'
                } : {
                    borderColor: ''
                }}
        >
            <CardContent>
                <h2>
                    {props.title}
                </h2>
                <p>
                    {props.desc}
                </p>
            </CardContent>
        </Card >
    )
}

export default Pricingplan