import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import ButtonAppBarCollapse from "./ButtonAppBarCollapse"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo05.png"

const styles = theme => ({
  root: {
    maxWidth: "75em",
    margin: "10px auto",
    display: "flex",
    "justify-content": "space-between",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    }
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      top: "50px !important",
      left: "0",
      width: "100%",
      height: "100vh",
      background: "white"
    },
    [theme.breakpoints.up("md")]: {
      display: "grid !important",
      "grid-template-columns": "auto auto",
      "align-items": "center"
    },
    width: "100%",
    background: "transparent"
  },
  left: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "flex-direction": "column",
    }
  },
  right: {
    "justify-self": "end",
    textAlign: 'center',

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "flex-direction": "column"
    }
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Nunito,sans-serif",
    fontWeight: "bold",
    margin: "0 25px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      marginBottom: "5px",
      textAlign: "center"
    },
    "&:hover": {
      color: "#FC5185"
    },
    cursor: "pointer"
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      margin: "0 auto"
    }
  },
  alink: {
    color: '#FC5185',
    textAlign: 'center',
    margin: "0 5px",
    fontFamily: "Nunito,sans-serif",
    fontWeight: "bold",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
      textAlign: "center",
      margin: "0 25px",
    },
    "&:hover": {
      color: "#FC5185"
    },
    cursor: "pointer"
  }
})

class AppNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isMenuOpen: false
    }

    this.hideMenuAfterClick = this.hideMenuAfterClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
    let collapsed = document.getElementById('appbar-collapse');
    if (this.state.isMenuOpen === true) {
      collapsed.style.display = "none";
      this.setState({ isMenuOpen: false });
    } else {
      collapsed.style.display = "block";
      collapsed.style.backgroundColor = "#fff";
      collapsed.style.top = "50px";
      collapsed.style.position = "absolute";
      collapsed.style.zIndex = 1450;
      this.setState({ isMenuOpen: true });


    }
  }

  hideMenuAfterClick() {
    let collapsed = document.getElementById('appbar-collapse');
    collapsed.style.display = "none";
    this.setState({ isMenuOpen: false });
  }

  render() {
    const classes = this.props.classes
    return (
      <div className={classes.root} >
        <NavLink exact to="/" className={classes.link}>
          <img onClick={this.hideMenuAfterClick} src={logo} className={classes.logo} alt="c3d logo" />
        </NavLink>
        <div className={classes.wrapper} onClick={this.hideMenuAfterClick} id="appbar-collapse">
          <div className={classes.left}>
            <NavLink
              exact
              to="/"
              className={classes.link} >
              Home
            </NavLink>
            <NavLink
              to="/school"
              className={classes.link} >
              School
            </NavLink>
            <NavLink
              to="/parents"
              className={classes.link} >
              Parents
            </NavLink>
            <NavLink
              to="/java"
              className={classes.link}>
              Java
            </NavLink>
            <NavLink
              to="/articles"
              className={classes.link} >
              Articles
            </NavLink>
            <NavLink
              to="/about"
              className={classes.link} >
              About
            </NavLink>
            <NavLink
              to="/pricing"
              className={classes.link} >
              Pricing
            </NavLink>
          </div>
          <div className={classes.right}>
            <a href='https://canada.c3d.io/login.html' className={classes.alink}>Personal Login</a>
          </div>
        </div>
        <ButtonAppBarCollapse toggleMenu={this.toggleMenu} hideMenuAfterClick={this.hideMenuAfterClick} dataTarget="#appbar-collapse" />
      </div>
    )
  }
}
AppNavigation.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(AppNavigation)