import React from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';
import GeneralBtn from "./elems/GeneralBtn";
import { useMediaQuery } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom'
import { Context } from "react-img-webp";
import { isWebpSupported } from '../../utils/isWebpSupport';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border-box',
        flexGrow: 1,
        position: "relative",
        height: "100%",
        width: "100vw",
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            margin: '0 auto',
            justifyContent: 'center',
            justifyItems: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            height: '500px',
            // height: '40vh'
        }
    },
    imgbg: {
        width: '100%',
        height: '100%',
        // minWidth: '100%',
        // minHeight: '100%',

    },
    container: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'left'
        },
        position: 'relative',
        textAlign: 'center',
        // maxWidth: '80vw',
        padding: "3vw 8vw",
        zIndex: 100
        // margin: '0 auto'
    },
    header: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            maxWidth: '550px',
        },
        position: 'relative',
        textAlign: "center",
        color: "white",

    },
    desc: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            maxWidth: '450px',
        },
        position: 'relative',
        margin: "3rem 0",
        textAlign: "center",
        fontSize: '1.3rem',
        color: "white",

    },
    btn: {
        position: 'relative',
        backgroundColor: "#11D3BC"
    },

}));

const HeroLeftText = props => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const classes = useStyles();
    const location = useLocation()
    const bgPosition = location.pathname === '/java' ? 'right' : 'center'
    const bgPositionX = location.pathname === '/parents' ? '75%' : 'center'

    return (
        <Context.WebP.Consumer>
            {(value) =>
                <Grid item xs={12} className={classes.root} style={{
                    background: `linear-gradient(
        rgba(0, 0, 0,.5), 
        rgba(0, 0, 0,.5)
    ),url(${isWebpSupported() ? props.imgWebp : props.img})`, backgroundPosition: `${bgPosition}`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
                }}>

                    <Grid item xs={12} className={classes.container} >
                        <ThemeProvider theme={theme}>
                            <h1 dangerouslySetInnerHTML={{ __html: props.header }} className={classes.header}></h1>
                            <p className={classes.desc}>{props.description}</p>
                        </ThemeProvider>

                        <GeneralBtn stylebtn={classes.btn} label={props.button ? props.button : "Get Started"} link={location.pathname !== "/school" ? props.signupLink : props.linkTeacherContact} />

                    </Grid>

                    {/* {isSmallScreen ? '' : */}
                    {/* <img className={classes.imgbg} src={props.img} alt="students sitting with teacher in class" /> */}
                    {/* } */}


                </Grid>
            }


        </Context.WebP.Consumer>
    )
}

export default HeroLeftText