import React from "react";
import { Paper, Grid, Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = theme => ({
    root: {
        margin: "25px 10px",
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        "-webkit-box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        "-moz-box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        "box-shadow": "0px 3px 17px 1px rgba(0,0,0,0.1)",
        maxWidth: 400,


    },
    header: {
        fontWeight: 700,
        margin: "0 auto",
        paddingBottom: "30px"
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse"
        }
    },
    item: {
        width: "100px",
        alignSelf: "flex-end",
        [theme.breakpoints.down("sm")]: {
            alignSelf: "center",
            width: "100%",
            // marginTop: "20px"
        }
    },
    bigAvatar: {
        width: "100px",
        height: "100px"
    },
    para: {
        maxWidth: "600px",
        margin: "2rem auto",
        height: 110,
        [theme.breakpoints.down("sm")]: {
            margin: "0 auto",
            height: 'auto',
        }
    },
    avatarContainer: {
        marginTop: '20px'
    }
});

const PTestimonial = props => {
    const classes = props.classes;
    return (
        <Paper className={classes.root} square display="flex">
            <Grid container space={40} className={classes.container}>
                <p className={classes.para}>
                    {props.title}
                </p>

                <Grid item xs container direction="column" >
                    <Grid item xs>
                        <p style={{ color: '#FC5185' }}>
                            {props.name}
                        </p>

                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

PTestimonial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PTestimonial);
