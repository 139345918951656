import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    root: {
        // minWidth: 345,
        maxWidth: 345,
        margin: "2rem",
        display:"block !important"
    },
    media: {
        height: 200,
    },
    btnRoot: {
        display: 'block !mportant',
        textAlign:'center'
    }
});

export default function Article(props) {
    // const { loading = false } = props;
    const [imgUrl, setimgUrl] = useState('')
    const [author, setAuthor] = useState('')
    // const [loading, setLoading] = useState(false)
    const history = useHistory();
    const classes = useStyles();
    useEffect(() => {
        const { featured_media, author } = props.article;
        const getImageUrl = axios.get(`https://wp.c3d.io/wp-json/wp/v2/media/${featured_media}`);
        const getAuthor = axios.get(`https://wp.c3d.io/wp-json/wp/v2/users/${author}`);

        Promise.all([getImageUrl, getAuthor]).then(res => {
            setimgUrl(res[0].data.media_details.sizes.full.source_url)
            setAuthor(res[1].data.name)
            // setLoading(true)
        });
    })

    // const clickHandler = (id) => {
    //     history.push(`/${id}`)
    // }

    const { title, excerpt, date, id } = props.article

    // console.log("title", excerpt)

    return (
        <Card className={classes.root}>
            {/* <ButtonBase onClick={}> */}

            <div className={classes.btnRoot} onClick={() => { history.push(`/articles/${id}#${title.rendered}`) }}>
                {
                    imgUrl ?
                        <img className={classes.media} src={imgUrl} alt="Contemplative Reptile" />
                        :
                        <Skeleton variant="rect" animation="wave" width='100%' height={200} />
                }

                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title.rendered}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        <span dangerouslySetInnerHTML={{ __html: excerpt.rendered }}></span>
                    </Typography>
                    <Typography display="block" gutterBottom>
                        Author: {author}
                    </Typography>
                    <Typography display="block" gutterBottom>
                        Date Posted: {date.substring(0, 10)}
                    </Typography>
                </CardContent>
            </div>
            {/* </ButtonBase> */}
        </Card>
    );
}