
const data = {
    hero: {
        header: "Pricing",
        description: "What are you looking for? We offer different packages based on your needs!",
        options: [
            {
                title: "C3D School License",
                desc: "Teacher, School, and District Licenses"
            },
            {
                title: "C3D Java/Python",
                desc: "High school to college level"
            },
            {
                title: "C3D Individual License",
                desc: "Personal Use"
            }
        ]
    },
    school: {
        title: "C3D School License",
        description: "For K-12 schools",
        details: "C3D school is a dedicated website for your school and its annual license includes access to 11 self-learning modules that cover coding concepts, teacher resources including lesson plans, instruction videos and student progress dashboard for modules and assignments. Seat licenses are reassignable to different students while the license is valid."
    },
    java: {
        title: "C3D Java",
        description: "For high school teachers and college professors teaching the AP CS curriculum or a beginner level computer science course",
        details: "C3D Java version is a tool that has transformed first-year Java class by making it very engaging and is mainly used in Advanced Placement Computer Science (APCS) classrooms at high school and introductory level computer science students at post-secondary institutions. CompSci teachers have loved this platform for over a decade.",
        plans: [
            {
                name: "Lesson Plan Sample",
                fee: "Free",
                btnDesc: "download now",
                link: ""
            },
            {
                name: "Full Lesson Plan",
                fee: "USD $240",
                btnDesc: "purchase now",
                link: ""
            }
        ]
    },
    individual: {
        title: "C3D Individual License",
        description: "C3D personal license is for users using C3D at home. In addition to full access to the C3D coding platform, you can follow 11 self-learning interactive lessons to learn to code.",
        // details: "C3D personal license is for users using C3D at home. In addition to full access to the C3D coding platform, you can follow 11 self-learning interactive lessons to learn to code. "
    },
};

export default data;
