import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 300,
        maxWidth: '80% !important',
        textAlign: 'left !important',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90% !important',
        },

    },
    titlef: {
        fontSize: 12,
        backgroundColor: "#11D3BC !important",
        borderRadius: "25px 25px 0 0 !important",
        margin: '0 !important',
        paddingLeft: "2% !important",
        color: "white",
        "&:hover": {
            color: "black",
        }
    },
    titlel: {
        fontSize: 12,
        backgroundColor: "#11D3BC !important",
        borderRadius: " 0 0 25px 25px !important",
        margin: '0 !important',
        paddingLeft: "2% !important",
        color: "white",
        "&:hover": {
            color: "black",
        }
    },
    title: {
        fontSize: 12,
        backgroundColor: "#11D3BC !important",
        margin: '0 !important',
        paddingLeft: "2% !important",
        color: "white",
        "&:hover": {
            color: "black",
        }
    },
}));

const Feature = props => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Grid item xs={12} className={classes.root} >
            <ListItem button onClick={handleClick} className={props.isfirst ? classes.titlef : (props.islast && !open ? classes.titlel : classes.title)}>
                <ListItemText primary={props.question} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List style={{padding:'20px !important'}}  component="div" >
                    <ListItem button>
                        <div dangerouslySetInnerHTML={{ __html: props.answer }}></div>
                    </ListItem>
                </List>
            </Collapse>

        </Grid>
    )
}

export default Feature