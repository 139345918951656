import coverWebp from "../assets/c3d-vr-coding.webp";
import cover from "../assets/c3d-vr-coding.jpg";
import tedtalk from "../assets/vr-coding-ted-talk.jpg";
import tedtalkWebp from "../assets/vr-coding-ted-talk.webp";
import tomoko from "../assets/Team/tomoko.jpg"
import jason from "../assets/Team/jason.jpg"
import kelly from "../assets/Team/kelly.jpg"
import jacob from "../assets/testimonial/jacob.png"
import jean from "../assets/Team/jean.png"
import sunny from "../assets/Team/sunny.png"
import olivia from "../assets/Team/olivia.png"
import nazanin from "../assets/Team/nazanin.png"
import sharon from "../assets/Team/sharon.png"
import kahlil from "../assets/testimonial/kahlil.png"
import sam from "../assets/Team/sam2.png"
import arsh from "../assets/Team/arsh.jpg"

const data = {
    hero: {
        title: "<span style='font-weight:100px !important'>CODING TOOL</span> <b>FOR EVERYONE</b>",
        header: "As a female programmer and teacher, Tomoko Okochi was looking for a way to make coding class fun and engaging for all students; not just for a handful of boys in a classroom, but for everyone including those who love to draw, those who love to write stories, and most importantly, majority of girls. When she met Jason Madar, an incredibly skilled developer and a parent of two girls, C3D came to life.",
        secondTitle: "<span style='font-weight:100px !important'>OUR</span> <b>STORY</b>",
        content: 'Jason was in an accident that held him from returning to his teaching role for one year in 2017. During that time, Jason decided to challenge himself with the most difficult task he could think of; developing a cloud-based virtual reality programming platform.<br/>Tomoko was looking for a way to make coding class engaging for every student.<br/>When the two met in 2018, C3D became a reality.',
        tomokoImg: tomoko,
        jasonImg: jason,
        tedtalkImg: tedtalk,
        tedtalkImgWebp: tedtalkWebp,
        description1: "Originally, Jason developed Env3D, an IDE with 3D output in 2006, which he succeeded in making the first-year computer science class very popular with.<br/>Fast forward 10 years, Jason migrated the platform to the web using Java transpiler; the birth of C3D Java version.",
        description2: "Jason did not stop there. He was in an accident that held him from returning to his teaching role for one year in 2017. During that time, Jason decided to challenge himself with the most difficult task he could think of; developing a groundbreaking, browser-based + cloud-based virtual reality block-programming platform. <br/>Tomoko was looking for a way to make coding class engaging for every student. When the two met in 2018, C3D came to life.",
        btnLink: {
            text: "Try java version",
            link: ""
        },
        button: "Get started",
        link: "",
        img: cover,
        imgWebp: coverWebp,
        alt: "cover",
        id: "teacher",
        quoteauthor: "<span>CTO / COMPUTER SCIENCE INSTRUCTOR</span> <br/><b>JASON</b> MADAR",
        quote: "When I started to teach computer science, I noticed right away that the text books are very boring and examples are difficult for students to identify with. Students generally like to have examples that they can identify with that they actually have personal experience with and I also enjoy giving them some room to be creative."
    },
    whatwedo: {
        title: "What We Do",
        plans: [
            {
                title: "Empower Educators",
                items: [{
                    desc: "Deliver an exciting coding curriculum with no coding knowledge",
                },
                {
                    desc: "Easy progress tracking system for each student"
                },
                {
                    desc: "Innovative web-based VR coding platform"
                }
                ]
            },
            {
                title: "Inspire Children",
                items: [{
                    desc: "Fun, and engaging by bringing visual code blocks to life",
                },
                {
                    desc: "Excited to create own VR worlds"
                },
                {
                    desc: "Learning coding like an art class"
                }
                ]
            },
        ]
    },
    team:
    {
        header: "Meet Our Team",
        teamMembers: [
            {
                name: "Tomoko Okochi",
                imgURL: tomoko,
                position: "CEO",
                description: `Tomoko is an experienced software program manager and educator. She cannot
      resist Indian food and baby Yoda.`
            }, {
                name: "Jason Madar",
                imgURL: jason,
                position: "CTO",
                description: `Jason is a highly skilled senior developer, leader and educator. He enjoys watching anime with his daughters.`
            }, {
                name: "Jean Nguyen",
                imgURL: jean,
                position: "Lead Developer",
                description: `Jean is a full-stack developer well-versed in web and mobile development. She cannot live without coffee.`
            },  {
                name: "Samuel Villegas",
                imgURL: sam,
                position: "Full-Stack Developer",
                description: `Sam is our tech guru. His curiosity helps him develop various solutions. He loves cooking, hiking, and snowboarding.`
            },{
                name: "Arsh Uppal",
                imgURL: arsh,
                position: "Full-Stack Developer",
                description: `Arsh is in charge of C3D.io Java version. He would always choose a book over a movie. His favorite author is Dr. Carl Sagan.`
            }, {
                name: "Olivia Underdah",
                imgURL: olivia,
                position: "Full-stack Developer",
                description: `Olivia is a full-stack developer with a love of problem solving. She loves skiing, snacks, and exploring all that Vancouver has to offer.`
            }, 
            // {
            //     name: "Sharon Olorunniwo",
            //     imgURL: sharon,
            //     position: "Digital Marketing Strategist",
            //     description: `Sharon is a rising Senior Computer Science student with a passion for technology, business and design. She is a life-long Chelsea F.C supporter – Go The Blues!`
            // }, {
            //     name: "Sunny Xue",
            //     imgURL: sunny,
            //     position: "Full-Stack Developer",
            //     description: `Sunny is a creative full-stack web developer. He enjoys playing video games in his free time.`
            // }, {
            //     name: "Leila",
            //     imgURL: leila,
            //     position: "C3D Student Ambassador",
            //     description: `Leila is an artistic C3D advocate currently in grade 9. She loves reading, writing, singing and making art.`
            // },
        ]
    },
    ambassadors:
    {
        lists: [
            {
                name: "Kahlil Ashanti",
                imgURL: kahlil,
                description: `Kahlil is an actor, web developer and founder of weshowup.io. Check out his C3D.io instructional videos <a href="https://www.youtube.com/playlist?list=PL1X6WcY4BMiwHZHaSJPujNyX-b72xrT41">here</a>. Learn more about Kahlil at <a href="https://kahlilashanti.com">kahlilashanti.com</a>.`
            }, {
                name: "Jacob Tran",
                imgURL: jacob,
                description: `Jacob has been using C3D.io in grade 10 to 12 CS classrooms since its beta version. Every summer he hatches baby quail birds and raise them with his daughters.`
            }, {
                name: "Kelly Nichols",
                imgURL: kelly,
                description: `Kelly uses C3D.io in grade 8 and 9 classrooms. She has lived and taught in China, UK, and Canada and has visited 28 countries and counting.`
            }
        ]
    }

};

export default data;
