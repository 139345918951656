import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Grid from "@material-ui/core/Grid"
import CenteredHeader from '../CenteredHeader'
import NestedList from '../cards/NestedList'

const useStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        display: 'block',
        // marginTop: '1rem',
        marginLeft: 'auto !important',
        marginRight: 'auto !important',
    },
    root: {
        [theme.breakpoints.down('xs')]: {
            width: '90% !important',
        },
        width: '70% !important',
        backgroundColor: theme.palette.background.paper,
        margin: '5px auto !important',
        // marginTop: '10%'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const LearningNestedList = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Grid item xs={12} className={classes.container}>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <CenteredHeader {...props} />
                }
                className={classes.root}
            >
                {props.body.map((value, index) => {
                    return (
                        <NestedList {...value} key={index} index={index} />
                    )
                })}

            </List>
        </Grid>
    );
}

export default LearningNestedList