import React, { useRef, useEffect } from 'react'

import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import data from "../../data/codinglessons"
import { isWebpSupported } from '../../utils/isWebpSupport';
import Lessonplans from '../parts/Lessonplans'



let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        height: "100%",
        width: "100%",
        marginBottom: "3rem",
        [theme.breakpoints.up('md')]: {
            // height: '500px',
        }
    },
    container: {
        textAlign: 'center',
        margin: '0 auto',
        padding: '5rem 0 3rem 0'
    },
    header: {
        position: 'relative',
        color: "white",
        textAlign: "center",


    },
    desc: {
        position: 'relative',
        maxWidth: '450px',
        margin: "3rem 0",
        textAlign: "center",

    },
    btn: {
        position: 'relative',
        backgroundColor: "#11D3BC",
    },

}));



const scrollToRefObject = (ref) => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })

const Codinglessons = () => {
    const classes = useStyles()
    const lessonPlans = useRef(Lessonplans)


    const lessonPlansScroll = () => {
        console.log('you are here')
        return scrollToRefObject(lessonPlans)
    }

    return (
        <div>

            <Grid item xs={12} className={classes.root} style={{
                background: `linear-gradient(
            rgba(0, 0, 0,.5), 
            rgba(0, 0, 0,.5)
        ),url(${isWebpSupported() ? data.hero.imgWebp : data.hero.img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
            }}>
                <Grid item xs={12} className={classes.container}>
                    <ThemeProvider theme={theme}>
                        <h2 className={classes.header} dangerouslySetInnerHTML={{ __html: data.hero.header }}></h2>
                        <h1 className={classes.header} dangerouslySetInnerHTML={{ __html: data.hero.title }}></h1>
                        {/* <GeneralBtn stylebtn={classes.btn} label={data.hero.btn} link={lessonPlansScroll()} /> */}
                    </ThemeProvider>

                </Grid>
            </Grid>

            <div ref={lessonPlans}>
                <Lessonplans {...data} />
            </div>


        </div>


    )
}

export default Codinglessons