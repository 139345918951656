import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from 'react-router-dom';



const styles = (theme, prop) => ({
    root: {
        display: "flex",
        "flex-direction": "column",
        alignItems: "center"
    },
    text: {
        //width: "33vw",
        textAlign: "left",
    },
    bullet: {
        color: "#FC5185",
    },
    list: {
        color: "#000000",
    },
    imageVis: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },

    },
})

class ChildLearn extends Component {
    render() {
        const classes = this.props.classes
        const theme = this.theme
        return (
            <div className={classes.root}>
                {/* child learn */}
                <Grid container style={{ padding: "10vw 0" }}>
                    <Grid item xs={12} sm={6}>
                        <div style={{ margin: "0vw 6vw" }}>
                            <h2 style={{ textTransform: "capitalize", fontWeight: "bold" }} className={classes.text}>{data.ChildLearn.title}</h2>
                            <h3 color="secondary" style={{ padding: "1.5vw 0", fontWeight: "600" }} className={classes.text}>{data.ChildLearn.subtitle1}</h3>
                            <p className={classes.text}>{data.ChildLearn.description}</p>
                            <div>
                                <ul className={classes.bullet}>
                                    {data.ChildLearn.learn.map((item, index) => (
                                        <li key={index} style={{ paddingBottom: "2vw" }}><span className={classes.list}>{item}</span></li>
                                    ))}
                                </ul>
                            </div>

                            <h3 style={{ padding: "1.5vw 0", fontWeight: "600", color: '#FC5185' }} className={classes.text}>{data.ChildLearn.subtitle2}</h3>
                            {/* <a href={} target="_blank" rel="#">{data.ChildLearn.link.caption}</a> */}
                            <Link to='/java'>{data.ChildLearn.link.caption}</Link>

                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <div className={classes.imageVis} style={{ position: "relative", marginLeft: "13vw" }}>
                            <img src={data.ChildLearn.img1} alt="" />
                            <img style={{ position: "absolute", top: "85%", right: "60%" }} src={data.ChildLearn.img2} alt="" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(ChildLearn)