import React, { Component } from "react";
import data from "../../../data/parents.js";
import { withStyles } from "@material-ui/core/styles";
import Feature from "../cards/Feature.js";

const styles = (theme, prop) => ({
    root: {
        // width: "100vw",
        marginTop: '2rem',
        padding: '1% 5%',
        display: "flex",
        "flex-direction": "column",
        alignItems: "center"
    },
    text: {
        textAlign: "center",
    },

    company: {
        //margin: "1.5vw",
        display: "inline-flex",
    },
})

class LearnToCodeGrid extends Component {
    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                {/* learn to code grid */}
                <h2 className={classes.text}>{data.LearnToCode.title}</h2>
                <p className={classes.text} style={{ margin: "5vh 5vw", maxWidth: 500 }}>{data.LearnToCode.description}</p>
                <div style={{ textAlign: "center", }}>
                    {data.LearnToCode.grid.map((item, index) => (
                        <div key={index} className={classes.company}>
                            <Feature {...item} />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(LearnToCodeGrid)