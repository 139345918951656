import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme, props) => ({
    root: {
        textAlign: 'center',
        height: 'auto',

    },
    title: {
        marginBottom: '2%'
    },
    content: {
        padding: '5%',
        backgroundColor: '#40514E',
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center"

    },
    item: {
        padding: "1%",
        color: "white",
        [theme.breakpoints.down('sm')]: {
            paddingBottom: "5%",
            maxWidth: "80%",
            alignItems: "center"
        },
    }

}));

const JavaLessonPlan = props => {
    const classes = useStyles();

    return (
        <Grid className={classes.root} >
            <CardContent>
                <h2 className={classes.title}>
                    {props.header}
                </h2>
                <p>
                    {props.description}
                </p>
            </CardContent>
            <Grid container sx={12} className={classes.content}>
                {props.details.map((item, index) => {
                    return (<Grid key={index} item xs={12} md={3} className={classes.item}>
                        <h3>
                            {item.title}
                        </h3>
                        <p>
                            {item.desc}
                        </p>
                    </Grid>)
                })
                }
            </Grid>

        </Grid>
    )
}

export default JavaLessonPlan